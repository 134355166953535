import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AdminSharedModule } from './../../shared/admin-shared.module';

import { AdminPrivacyConditionsComponent } from './privacy-conditions/admin-privacy-conditions.component';
import { AdminSignInComponent } from './sign-in/admin-sign-in.component';

const NG_COMPONENTS = [AdminPrivacyConditionsComponent, AdminSignInComponent];

@NgModule({
  imports: [AdminSharedModule, RouterModule],
  declarations: [...NG_COMPONENTS],
})
export class AdminLoginModule {}
