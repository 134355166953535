import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-admin-privacy-conditions',
  templateUrl: './admin-privacy-conditions.component.html',
  styleUrls: ['./admin-privacy-conditions.component.scss'],
})
export class AdminPrivacyConditionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
