import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';
import { IBitfApiRequestPartChips } from '@interfaces';

export class AdminChildrenRegistryListFilterApiRequestPart extends BitfApiRequestPart {
  constructor() {
    super(EApiRequestPartKeys.FILTERS, EBitfApiRequestPartsNames.FILTER);
  }

  build(): void {
    this.chips = [];
    this.part = '';

    if (this.formValue['class']) {
      this.chips.push({
        apiRequestPartItemName: 'class',
        chip: this.formValue.class.map(sClass => {
          return { key: sClass.id, value: sClass.name };
        }),
        label: 'Class',
        apiRequestPartKey: this.key,
      } as IBitfApiRequestPartChips);
    } else if (this.formValue['schoolId']) {
      this.chips.push({
        apiRequestPartItemName: 'schoolId',
        chip: [{ key: this.data.school.id, value: this.data.school.name }],
        label: 'School',
        apiRequestPartKey: this.key,
      } as IBitfApiRequestPartChips);
    }

    let partBuilder = '';
    for (let [index, item] of [
      (this.formValue['class'] &&
        `(${this.formValue.class.map(sClass => `classId eq ${sClass.id}`).join(' or ')})`) ||
        null,
      (this.formValue['schoolId'] && `(class/schoolId eq ${this.data.school.id})`) || null,
      this.formValue['childrenBeacon'] || null,
      this.formValue['childrenList'] || null,
    ].entries()) {
      if (item) {
        partBuilder += `${partBuilder === '' ? '' : ' and '}` + item;
      }
    }
    this.part = partBuilder;
  }
}
