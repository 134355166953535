import { SuperModel } from './super-model.model';

export class Location extends SuperModel {
  latitude: number;
  longitude: number;
  address?: string;
  time?: string;
  order?: number;

  constructor(data: any = {}) {
    super(data);
    if (data.time) {
      this.time = this.parseTimeFormat(data.time);
    }
    if (!data.createdAt) {
      this.createdAt = new Date();
    } else {
      this.createdAt = new Date(this.createdAt);
    }
  }

  get serialised() {
    return {
      id: this.id,
      createdAt: this.createdAt && (this.createdAt as Date).toISOString(),
      latitude: this.latitude,
      longitude: this.longitude,
      address: this.address,
      time: this.time,
      order: this.order,
    };
  }

  get googleLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(this.latitude, this.longitude);
  }

  set googleLatLng(googleLatLng: google.maps.LatLng) {
    this.latitude = googleLatLng.lat();
    this.longitude = googleLatLng.lng();
  }
}
