import { EBitfStoreActions } from '@common/enums';

export enum EChildPositionIcon {
  IN_BUS = 'check_circle',
  NOT_IN_BUS = 'remove_circle_outline',
  WILL_NOT_SHOW = 'warning',
  NOT_ACTIVATED = 'link_off',
}

export enum EChildPositionIconColor {
  IN_BUS = 'green-color',
  NOT_IN_BUS = 'grey-color',
  WILL_NOT_SHOW = 'orange-color',
  NOT_ACTIVATED = 'grey-color',
}

export enum EBatteryPercentageIcon {
  _0_20 = 'battery_alert',
  _20_30 = 'battery_20',
  _30_50 = 'battery_30',
  _50_60 = 'battery_50',
  _60_80 = 'battery_60',
  _80_90 = 'battery_80',
  _90_95 = 'battery_90',
  _95_100 = 'battery_full',
  DEFAULT = 'battery_unknown',
}

export enum EBatteryPercentageIconColor {
  _0_20 = 'red-color',
  _20_30 = 'orange-color',
  _30_50 = 'green-color',
  _50_60 = 'green-color',
  _60_80 = 'green-color',
  _80_90 = 'green-color',
  _90_95 = 'green-color',
  _95_100 = 'green-color',
  DEFAULT = 'grey-color',
}

export enum EAdminStoreActions {
  UPDATE_BUS_TRACKER = 'UPDATE_BUS_TRACKER',
}

export const EStoreActions = { ...EBitfStoreActions, ...EAdminStoreActions };
