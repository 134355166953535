<div class="d-flex flex-column admin-dialog cm-admin-create-child px-4">
  <div class="row justify-content-between align-items-center">
    <div class="col">
      <h1>{{ 'ADMIN.COMMON.FILTER' | translate }}</h1>
    </div>
    <div class="col-auto">
      <button mat-flat-button
        (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row cm-scroll-y flex-grow-1">
    <div class="col-24">
      <ng-container #filtersContainer></ng-container>
    </div>
  </div>
  <div class="row text-right justify-content-end p-2">
    <button mat-flat-button
      class="col-auto"
      color="primart"
      (click)="onReset()">
      {{ 'COMMON.BUTTON.RESET' | translate }}
    </button>
    <button mat-flat-button
      class="col-auto"
      color="accent"
      (click)="onFilter()">
      {{ 'COMMON.BUTTON.FILTER' | translate }}
    </button>
  </div>
</div>