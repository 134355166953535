import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import justClone from 'just-clone';

import { IBitfStoreEvent } from '@interfaces';
import { EStoreActions } from '@enums';
import { BitfStorageService } from '../storage/bitf-storage.service';

export abstract class BitfStoreService<T> {
  protected _store: T;
  protected StoreClass: new (data?: Partial<T>) => T;
  protected storage: BitfStorageService<T>;
  protected initialData: Partial<T>;
  public readonly store$ = new Subject<IBitfStoreEvent<T>>();

  // NOTE: inital data must be an object to be passed in the StoreClass constructor not a storeClass instance
  // this is to avoid to create something like new StoreClass(justClone(StoreClassInstance)); which will
  // lead to problems
  constructor({
    initialData,
    StoreClass,
    storage,
  }: {
    initialData?: Partial<T>;
    StoreClass: new (data?: Partial<T>) => T;
    storage?: BitfStorageService<T>;
  }) {
    if (!StoreClass) {
      throw new Error('StorageClass is undefined');
    }
    this.StoreClass = StoreClass;
    this.initialData = initialData || ({} as T);
    this.storage = storage;
    if (storage) {
      this._store = storage.data;
    } else {
      this._store = new StoreClass(justClone(this.initialData));
    }
  }

  get store(): T {
    return this._store;
  }

  resetStore() {
    // FIME: this will break the apiCallStates since in the BitfRequestPart.init() there we assign the
    // store in the constructor and then it change
    if (this.storage) {
      this.storage.resetStorage();
      this._store = this.storage.data;
    } else {
      this._store = new this.StoreClass(justClone(this.initialData));
    }
    window['store'] = this._store;
    this.store$.next({ action: EStoreActions.RESET, store: this.store } as IBitfStoreEvent<T>);
  }

  // NOTE: this '= EStoreActions.CREATE' will infer the action EStoreActions also if it is a constant
  setStore(fn: (store: T) => void, action: string = EStoreActions.UPDATE) {
    fn(this.store);
    if (this.storage) {
      this.storage.setData(this.store);
    }
    window['store'] = this._store;
    this.store$.next({ action, store: this.store } as IBitfStoreEvent<T>);
  }

  selectStore(action: string) {
    return this.store$.pipe(filter(item => item.action === action));
  }
}
