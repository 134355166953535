import { HttpResponse, HttpRequest } from '@angular/common/http';

import {
  IBitfEnvelopeMapper,
  IBitfEnvelopeMapperData,
  IBitfApiResponse,
  IBitfApiPagination,
  IBitfApiSorting,
  IBitfApiUiMessage,
} from '@interfaces';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

import { BitfRestEnvelopeMapper } from '../rest-parser/bitf-rest-envelope.mapper';

export abstract class BitfODataEnvelopeMapper extends BitfRestEnvelopeMapper implements IBitfEnvelopeMapper {
  map({ req, event, parserStrategy }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    event = super.map({ req, event });
    const mappedBody: IBitfApiResponse<any> = event.body;
    const originalBody = event.body.originalBody;

    if (originalBody != null) {
      const uiMessagesKey = `${parserStrategy.namespace || '@bitf'}.${parserStrategy.uiMessageKey ||
        'uiMessages'}`;
      let uiMessages: IBitfApiUiMessage[] = [];
      const bodyUiMessages = originalBody[uiMessagesKey];
      if (bodyUiMessages) {
        uiMessages = bodyUiMessages.map((uiMessage: any) => ({
          ...uiMessage,
          // @kouti are these weird mapping coming from Aboca?
          message: uiMessage.message || uiMessage.text,
          code: uiMessage.code || uiMessage,
          type: uiMessage.type || uiMessage.messageType,
        }));
        mappedBody.metadata = { uiMessages };
      }

      if (this.isArrayResponse(originalBody, 'value')) {
        // NOTE: standard array response
        mappedBody.content = originalBody.value;
        mappedBody.pagination = this.mapPagination(req, originalBody);
        mappedBody.sorting = this.mapSorting(req);
      } else if (this.isArrayResponse(originalBody, 'responses')) {
        // NOTE: array response after batch action
        mappedBody.content = originalBody.responses;
      } else {
        // NOTE: response with single object
        mappedBody.content = originalBody;
      }

      if ('@odata.context' in mappedBody.content) {
        delete mappedBody.content['@odata.context'];
      }
    }

    return event.clone<IBitfApiResponse<any>>({
      body: mappedBody,
    });
  }

  private isArrayResponse(body: any, prop: string) {
    if (body[prop] && Array.isArray(body[prop])) {
      return (
        Object.keys(body).filter((key: string) => {
          return key[0] !== '@';
        }).length === 1
      );
    }
    return false;
  }

  private mapPagination(req: HttpRequest<any>, content: any): IBitfApiPagination {
    const totalItems = parseInt(bitfGetProp(content, '@odata.count'), 10);
    const top = req.params.get('$top');
    const skip = req.params.get('$skip');
    if (!totalItems || top == null || skip == null) {
      return null;
    }
    const size = parseInt(top, 10) || 10;
    const page = Math.ceil((parseInt(skip, 10) || 0) / size + 1);
    const totalPages = Math.ceil(totalItems / size);
    return {
      page,
      size,
      totalItems,
      totalPages,
      first: page === 1,
      last: page === totalPages,
      itemsInPage: content.value instanceof Array ? content.value.length : 0,
    } as IBitfApiPagination;
  }

  private mapSorting(req: HttpRequest<any>) {
    const orderby = req.params.get('$orderby');
    if (!orderby) {
      return null;
    }
    return orderby.split(',').map(item => {
      const sorter = item.split(' ');
      return {
        property: sorter[0],
        direction: sorter[1].toUpperCase(),
      } as IBitfApiSorting;
    });
  }
}
