import { Component, OnInit, Injector } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AppSessionService } from '@services';
import { CommonLayout } from '@common/core/components/layout/common-layout.component';
import { environment } from '@env/environment';

@Component({
  selector: 'cm-app',
  templateUrl: './admin-app.component.html',
  styleUrls: ['./admin-app.component.scss'],
})
export class AdminAppComponent extends CommonLayout implements OnInit {
  constructor(
    private appSessionService: AppSessionService,
    public injector: Injector,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    super(injector);
    console.log('ENV', environment);
    this.appSessionService.init();
    this.matIconRegistry.addSvgIcon(
      'battery_20',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_20.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_30',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_30.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_50',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_50.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_60',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_60.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_80',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_80.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_90',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_90.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_full.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_alert.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_unknown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_unknown.svg')
    );
  }

  async ngOnInit() {
    super.ngOnInit();
  }

  updateLayout(): void {
    const data = this.route.snapshot.firstChild
      ? this.route.snapshot.firstChild.data
      : this.route.snapshot.data;
    this.showHeader = data.showHeader;
  }
}
