import { SuperModel } from './super-model.model';
import { Location } from './location.model';

export class BusStopsLocations extends SuperModel {
  pickUpLocations: Array<Location>;
  dropOffLocations: Array<Location>;
  arePickUpAndDropOffTheSame: boolean;

  constructor(data: any = {}) {
    super(data);

    if (data.pickUpLocations) {
      this.pickUpLocations = data.pickUpLocations.map(location => new Location(location));
    }

    if (this.arePickUpAndDropOffTheSame) {
      this.dropOffLocations = this.pickUpLocations.slice();
    } else if (data.dropOffLocations) {
      this.dropOffLocations = data.dropOffLocations.map(location => new Location(location));
    }
  }

  get serialised() {
    return {
      pickUpLocations: this.pickUpLocations && this.pickUpLocations.map(location => location.serialised),
      dropOffLocations: this.dropOffLocations && this.dropOffLocations.map(location => location.serialised),
    };
  }
}
