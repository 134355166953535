export enum EApiCallStateNames {
  CHILDREN_REGISTRY = 'CHILDREN_REGISTRY',
  CHILDREN_REGISTRY_ITEM = 'CHILDREN_REGISTRY_ITEM',
  CHILDREN_LISTS = 'CHILDREN_LISTS',
  CHILDREN_LISTS_ITEM = 'CHILDREN_LISTS_ITEM',
  BUSES = 'BUSES',
  BUSES_ITEM = 'BUSES_ITEM',
  STAFF = 'STAFF',
  STAFF_ITEM = 'STAFF_ITEM',
  ROUTES = 'ROUTES',
  ROUTES_ITEM = 'ROUTES_ITEM',
  SCHOOLS = 'SCHOOLS',
  SCHOOLS_ITEM = 'SCHOOLS_ITEM',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  FILTERS = 'FILTERS',
}
