import { NgModule, LOCALE_ID } from '@angular/core';

import { AdminAppRoutingModule } from './admin-app-routing.module';
import { AdminAppComponent } from './admin-app.component';

import { AdminCoreModule } from './core/admin-core.module';
import { AdminSharedModule } from './shared/admin-shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { BitfDynamicLocaleService } from '@bitf/services/locale/bitf-dynamic-locale.service';

@NgModule({
  declarations: [AdminAppComponent],
  imports: [
    AdminAppRoutingModule,
    AdminCoreModule,
    AdminSharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.registerServiceWorker }),
  ],
  bootstrap: [AdminAppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [BitfDynamicLocaleService],
      useFactory: (dynamicLocaleService: BitfDynamicLocaleService) => dynamicLocaleService.defaultLocale.code,
    },
  ],
})
export class AdminAppModule {}
