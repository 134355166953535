import { Injectable } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';

import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

import { CONSTANTS } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class BitfMetadataService {
  prefixLoaded = false;
  titlePrefix = '';
  titlePostfix = '';

  constructor(private meta: Meta, private title: Title, private translateService: TranslateService) {}

  init() {
    const metaDescription = bitfGetProp(CONSTANTS, 'META_TAGS', 'DESCRIPTION');
    if (metaDescription) {
      this.updateMetaTags([{ name: 'description', content: metaDescription }]);
    }
    const pageTitle = bitfGetProp(CONSTANTS, 'META_TAGS', 'TITLE', 'DEFAULT_TITLE');
    if (pageTitle) {
      this.updateTitle(pageTitle);
    }
  }

  loadPrefixTranslations() {
    if (this.prefixLoaded) {
      return Promise.resolve();
    }

    const obs$: Observable<string>[] = [];
    const prefix = bitfGetProp(CONSTANTS, 'META_TAGS', 'TITLE', 'PREFIX');
    if (prefix) {
      const titlePrefix$ = this.translateService.get(prefix);
      titlePrefix$.subscribe(translation => {
        this.titlePrefix = translation;
      });
      obs$.push(titlePrefix$);
    }
    const postfix = bitfGetProp(CONSTANTS, 'META_TAGS', 'TITLE', 'POSTFIX');
    if (postfix) {
      const titlePostfix$ = this.translateService.get(postfix);
      titlePostfix$.subscribe(translation => {
        this.titlePostfix = translation;
      });
      obs$.push(titlePostfix$);
    }
    this.prefixLoaded = true;
    return forkJoin(obs$).toPromise();
  }
  /**
   * Update the HTML Title
   * @param {string} title
   * @return {void}
   */
  public async updateTitle(title: string) {
    await this.loadPrefixTranslations();
    title = this.translateService.instant(title);
    this.title.setTitle(`${this.titlePrefix}${title}${this.titlePostfix}`);
  }

  /**
   * Update the metaTags
   * @param {MetaDefinition[]} metaTags
   * @return {void}
   */
  public updateMetaTags(metaTags: MetaDefinition[]): void {
    metaTags.forEach(metaTag => {
      this.translateService.get(metaTag.content).subscribe(content => {
        metaTag.content = content;
        this.meta.updateTag(metaTag);
      });
    });
  }
}
