import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

import { IBitfApiRequest, IBitfApiResponse, IBitfApiAction } from '@interfaces';
import { User, Child } from '@models';
import { StoreService } from '@services';
import { environment } from '@env/environment';
import { CONSTANTS } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  mockApiUrl = environment.mockApiUrl;
  mockApiParser = 'mockDbParser';
  childrenStatusPoolingInterval = 10000;
  private storeService: StoreService;

  constructor(protected injector: Injector) {
    super('users', injector);
  }

  getMe(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<User>> {
    return super.request<User>({
      ...requestParams,
      disableHideLoader: true,
      body: { role: CONSTANTS.userRole },
      isBodyRaw: true,
      path: '/me',
      method: 'POST',
    });
  }

  getChildren(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<Child[]>> {
    const storeService = this.injector.get(StoreService);
    return this.getRel<Child[]>({
      ...requestParams,
      id: requestParams.id ? requestParams.id : bitfGetProp(storeService.store, 'user', 'id'),
      relation: 'children',
    });
  }

  linkChild(childId: number): Observable<IBitfApiResponse<IBitfApiAction>> {
    const storeService = this.injector.get(StoreService);
    return this.linkRel<User>({
      id: storeService.store.user.id,
      relation: 'children',
      relationRefId: `${environment.apiUrl}children(${childId})`,
    });
  }

  unLinkChild(childId: number): Observable<IBitfApiResponse<IBitfApiAction>> {
    const storeService = this.injector.get(StoreService);
    return this.unLinkRel<User>({
      id: storeService.store.user.id,
      relation: 'children',
      relationRefId: `${environment.apiUrl}children(${childId})`,
    });
  }

  resetPassword(user: User): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request({
      id: user.id,
      path: '/reset-password',
      method: 'POST',
    });
  }
}
