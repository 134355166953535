import { Component, OnInit, Input, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';

import { SuperModel } from '@common/core/models/super-model.model';
import { ApiCallStateService } from '@services';
import { IBitfApiPagination, IBitfApiCallState, IAdminSearchSettings } from '@interfaces';
import { CONSTANTS } from '@constants';
import { EBitfApiCallStateActions, EApiCallStateNames } from '@enums';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'cm-admin-crud-presenter',
  templateUrl: './admin-crud-presenter.component.html',
  styleUrls: ['./admin-crud-presenter.component.scss'],
})
export class AdminCrudPresenterComponent implements OnInit {
  @Input()
  listTitle;

  @Input()
  rightBoxTitle;

  @Input()
  apiCallStateName: EApiCallStateNames;

  @Input()
  items: Array<SuperModel>;

  @Input()
  selectable = true;

  @Input()
  editable = true;

  @Input()
  removable = true;

  @Input()
  pagination: IBitfApiPagination;

  @Input()
  searchPlaceholder = 'search';

  @Input()
  searchSettings: IAdminSearchSettings;

  @ContentChild('listItemTemplate', { static: false })
  listItemTemplate: TemplateRef<any>;

  @ContentChild('itemDetailsTemplate', { static: false })
  itemDetailsTemplate: TemplateRef<any>;

  @Output()
  listItemSelected: EventEmitter<SuperModel> = new EventEmitter<SuperModel>();

  @Output()
  create: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  edit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  filter: EventEmitter<any> = new EventEmitter<any>();

  rightBoxVisible = false;
  pageSizeOptions = CONSTANTS.pageSizeOptions;
  filtersHidden = true;
  searchForm: FormGroup;
  minLengthOfSearchKeyword = 3;
  selectedItem: SuperModel;

  constructor(private apiCallStateService: ApiCallStateService, private fb: FormBuilder) {}

  ngOnInit() {}

  onPageChange(event) {
    this.selectedItem = undefined;
    const apiCallState = this.apiCallStateService.getApiCallState(this.apiCallStateName);
    Object.assign(apiCallState, {
      apiRequest: {
        ...apiCallState.apiRequest,
        page: event.pageIndex + 1,
        size: event.pageSize,
      },
    } as IBitfApiCallState);
    this.apiCallStateService.dispatchApiCallState(EBitfApiCallStateActions.UPDATE, this.apiCallStateName);
  }

  setSelectedItem(selectedItem: SuperModel) {
    this.selectedItem = selectedItem;
  }

  showRightBox() {
    this.rightBoxVisible = true;
  }

  hideRightBox() {
    this.rightBoxVisible = false;
    this.selectedItem = undefined;
  }

  hideFilters() {
    this.filtersHidden = true;
  }

  showFilters() {
    this.filtersHidden = false;
  }

  trackByFn(index, item) {
    return item.id;
  }
}
