import { Component, OnInit, TemplateRef } from '@angular/core';

import { AdminMenuComponent } from '../menu/admin-menu.component';
import { CommonHeaderComponent } from '@common/core/components/header/common-header.component';

@Component({
  selector: 'cm-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent extends CommonHeaderComponent implements OnInit {
  menuComponent = AdminMenuComponent;
  headerExtraContentTemplate: TemplateRef<any> = null;

  ngOnInit() {
    super.ngOnInit();
    this.appSessionService.headerExtraContentTemplate$.subscribe(
      (headerExtraContentTemplate: TemplateRef<any>) => {
        setTimeout(() => (this.headerExtraContentTemplate = headerExtraContentTemplate));
      }
    );
  }
}
