import { School } from './school.model';
import { SuperModel } from './super-model.model';

export class Class extends SuperModel {
  name: string;
  schoolId?: number;
  school: School;

  constructor(data: any = {}) {
    super(data);
    if (data.school) {
      this.school = new School(data.school);
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      schoolId: this.schoolId,
      school: this.school && this.school.serialised,
    };
  }
}
