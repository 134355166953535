import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BitfDynamicLocaleService } from '../core/services/locale/bitf-dynamic-locale.service';

@Pipe({ name: 'bitfDate', pure: false })
export class BitfDatePipe extends DatePipe implements PipeTransform {
  private lastLocale: string;
  private lastResult: string;
  private lastValue: any;

  constructor(
    @Inject(LOCALE_ID) defaultLocale: string,
    private bitfDynamiclocaleService: BitfDynamicLocaleService
  ) {
    super(defaultLocale);
  }

  transform(value: any, format: string, timezone?: string, locale?: string): string {
    locale = locale || this.bitfDynamiclocaleService.locale.code;
    if (value !== this.lastValue || locale !== this.lastLocale) {
      this.lastLocale = locale;
      this.lastResult = super.transform(value, format, timezone, locale);
      this.lastValue = value;
    }

    return this.lastResult;
  }
}
