import { SuperModel } from './super-model.model';
import { Route } from './route.model';
import { ERouteType, EUserRoles } from '@enums';
import { User } from './user.model';
import { ChildrenList } from './children-list.model';
import { Bus } from './bus.model';

export class RoutePlan extends SuperModel {
  name: string;
  routes: Array<Route>;
  staff: Array<User>;
  buses: Bus[];
  driverId: number;
  childrenList: ChildrenList;
  childrenListId: number;
  currentRouteId: number;
  currentDriverId: number;
  currentAssistantId: number;
  currentBusId: number;
  startedAt: Date;
  finishedAt: Date;

  constructor(data: any = {}) {
    super(data);

    if (data.routes) {
      this.routes = data.routes.map(route => new Route(route));
    } else {
      this.routes = [];
    }

    if (data.staff) {
      this.staff = data.staff.map(person => new User(person));
    } else {
      this.staff = [];
    }

    if (data.childrenList) {
      this.childrenList = new ChildrenList(data.childrenList);
    }

    if (data.buses) {
      this.buses = data.buses.map(bus => new Bus(bus));
    } else {
      this.buses = [];
    }
  }

  get drivers(): User[] {
    return (this.staff && this.staff.filter(d => d.role === EUserRoles.DRIVER)) || [];
  }

  get assistants(): User[] {
    return (this.staff && this.staff.filter(d => d.role === EUserRoles.ASSISTANT)) || [];
  }

  getRouteByType(routeType: ERouteType) {
    return this.routes && this.routes.find((route: Route) => route.type === routeType);
  }

  get route(): Route {
    return this.routes[0];
  }

  get currentRoute(): Route {
    return (
      (this.currentRouteId &&
        this.routes &&
        this.routes.find((route: Route) => route.id === this.currentRouteId)) ||
      null
    );
  }

  get currentBus(): Bus {
    return (
      (this.currentBusId && this.buses.length && this.buses.find(bus => bus.id === this.currentBusId)) || null
    );
  }

  get currentDriver(): User {
    return (
      (this.currentDriverId && this.drivers && this.drivers.find(d => d.id === this.currentDriverId)) || null
    );
  }

  get currentAssistant(): User {
    const assistants: User[] = this.assistants;
    return (
      (this.currentAssistantId &&
        assistants &&
        assistants.find(assistant => assistant.id === this.currentAssistantId)) ||
      null
    );
  }

  get currentDriverAndAssistant(): User[] {
    const list: User[] = [];
    const currentDriver = this.currentDriver;
    if (currentDriver) {
      list.push(currentDriver);
    }
    const currentAssistant = this.currentAssistant;
    if (currentAssistant) {
      list.push(currentAssistant);
    }
    return list;
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      routes: this.routes && this.routes.map((route: Route) => route.serialised),
      staff: this.staff && this.staff.map(person => person.serialised),
      buses: this.buses && this.buses.map(bus => bus.serialised),
      childrenList: this.childrenList && this.childrenList.serialised,
      childrenListId: this.childrenListId,
    };
  }

  getRoutePlanToSave() {
    return {
      plan: {
        id: this.id,
        name: this.name,
        childrenListId: this.childrenListId,
        routes:
          this.routes &&
          this.routes.map(route => {
            const serialisedRoute = route.serialised;
            serialisedRoute.locations =
              serialisedRoute.locations &&
              serialisedRoute.locations.map(location => {
                return location;
              });
            return serialisedRoute;
          }),
      },
      staffIds: this.staff && this.staff.length ? this.staff.map(person => person.id) : [],
      busesIds: this.buses && this.buses.length ? this.buses.map(bus => bus.id) : [],
    };
  }
}
