import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { IBitfApiAction, IBitfApiResponse, IBitfApiRequest } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class RoutePlansService extends BitfApiService {
  constructor(public injector: Injector) {
    super('route-plans', injector);
  }

  save(request: IBitfApiRequest = {}): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request<IBitfApiAction>({
      ...request,
      path: '/save-plan',
      method: 'POST',
      isBodyRaw: true,
    });
  }
}
