import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@services';
import { EBitfAuthState } from '@enums';
import { APP_VERSION } from '@env/version';
import { environment } from '@env/environment';
@Component({
  selector: 'cm-sign-in',
  templateUrl: './admin-sign-in.component.html',
  styleUrls: ['./admin-sign-in.component.scss'],
})
export class AdminSignInComponent implements OnInit, OnDestroy {
  authState: EBitfAuthState = EBitfAuthState.TO_BE_LOGGED;
  eBitfAuthState = EBitfAuthState;
  subscriptions: Subscription = new Subscription();
  APP_VERSION = APP_VERSION;
  environment = environment;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.authService.authState$.subscribe((authState: EBitfAuthState) => {
        this.authState = authState;
      })
    );
    this.authService.handleAuthentication();

    // Try autologin
    this.authService
      .renewToken()
      .then(() => {
        this.authService.handleRedirect();
      })
      .catch(() => {});
  }

  onSignIn() {
    this.authService.auth0Login();
  }

  onChangeAccount() {
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
