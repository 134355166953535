import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { IBitfApiRequest } from '@interfaces';
import { Child } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ChildrenService extends BitfApiService {
  constructor(protected injector: Injector) {
    super('children', injector);
  }

  getByBeaconAddress(address: string) {
    return super.action<Child>({
      action: '/get-by-beacon-address',
      actionParams: `address='${address}'`,
    } as IBitfApiRequest);
  }
}
