import { BitfApiCallStateMapper } from '@bitf/core/api-call-state/bitf-api-call-state.mapper';
import { IBitfApiCallState } from '@interfaces';
import { EUserRoles } from '@enums';

export class AdminStaffApiCallStateMapper extends BitfApiCallStateMapper {
  buildApiRequest(apiCallState: IBitfApiCallState): void {
    this.computedApiRequestParts.filter.push(
      `(role eq '${EUserRoles.DRIVER}' or role eq '${EUserRoles.ASSISTANT}')`
    );
    apiCallState.apiRequest.filter = this.computedApiRequestParts.filter
      .filter(filterString => filterString)
      .join(' and ');
  }
}
