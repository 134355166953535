import { Injectable } from '@angular/core';

import { BitfStoreService } from '@bitf/services/store/bitf-store.service';

import { Store } from '@models';
import { StoreStorageService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class AdminStoreService extends BitfStoreService<Store> {
  constructor(storeStorageService: StoreStorageService) {
    super({ StoreClass: Store, storage: storeStorageService });
  }
}
