import { User } from '@models';
import justClone from 'just-clone';

export abstract class SuperModel {
  id?: number;
  createdAt?: string | Date | number;
  modifiedAt?: string | Date | number;
  createdBy: string | User;
  modifiedBy: string | User;

  constructor(object: any = {}) {
    Object.assign(this, object);
    if (!object) {
      return;
    }

    if (object.createdAt) {
      this.createdAt = new Date(object.createdAt);
    }

    if (object.modifiedAt) {
      this.modifiedAt = new Date(object.modifiedAt);
    }

    if (typeof object.createdBy === 'object') {
      this.createdBy = new User(object.createdBy);
    }
    if (typeof object.modifiedBy === 'object') {
      this.modifiedBy = new User(object.modifiedBy);
    }
  }

  abstract readonly serialised;

  get clone() {
    return justClone(this.serialised);
  }

  parseTimeFormat(time: string): string {
    return time.substr(0, 5);
  }
}
