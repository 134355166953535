import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { RoutePlan, Route, Location } from '@models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DeprecatedBatchService extends BitfApiService {
  constructor(public injector: Injector) {
    super('$batch', injector);
  }

  saveRoutePlan(routePlan: RoutePlan) {
    const routePlanToSave = routePlan.clone;
    delete routePlanToSave['routes'];
    delete routePlanToSave['staff'];
    delete routePlanToSave['bus'];
    delete routePlanToSave['childrenList'];
    delete routePlanToSave['name'];
    const requests = [
      {
        id: '1',
        method: 'POST',
        atomicityGroup: 'group1',
        url: `${environment.apiUrl}route-plans`,
        body: routePlanToSave,
        headers: {
          ['Content-Type']: 'application/json',
        },
      } as BatchRequest,
    ];
    // remove removed locations
    // update existing location
    // create new locations
    // update routes
    // add  routes
    // link locations to routes
    // link routes to route plan

    routePlan.routes.forEach((route: Route) => {
      const routeRequestId = (requests.length + 1).toString();
      requests.push({
        id: routeRequestId,
        method: 'POST',
        url: `${environment.apiUrl}routes`,
        body: route.serialised,
        atomicityGroup: 'group1',
        headers: {
          ['Content-Type']: 'application/json',
        },
      } as BatchRequest);
      route.locations.forEach((location: Location) => {
        const locationToSave = location.serialised;
        locationToSave['routeId'] = `$${routeRequestId}.id`;
        locationToSave.time = new Date().toISOString();
        requests.push({
          id: (requests.length + 1).toString(),
          method: 'POST',
          url: `${environment.apiUrl}locations`,
          body: locationToSave,
          atomicityGroup: 'group1',
          dependsOn: [routeRequestId],
          headers: {
            ['Content-Type']: 'application/json',
          },
        } as BatchRequest);
      });
    });

    this.post({
      body: JSON.stringify({ requests }),
      isBodyRaw: true,
      headers: [{ headerName: 'Content-type', value: 'application/json' }],
    }).subscribe(response => {
      console.log(response);
    });
  }
}

export interface BatchRequest {
  id: string;
  url: string;
  method: string;
  body: any;
  atomicityGroup: string;
  dependsOn?: Array<string>;
  headers?: any;
}
