import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['bus-stops-locations', Models.BusStopsLocations],
  ['users', Models.User],
  ['classes', Models.Class],
  ['children-lists', Models.ChildrenList],
  ['routes', Models.Route],
  ['children', Models.Child],
  ['buses', Models.Bus],
  ['drivers', Models.User],
  ['schools', Models.School],
  ['$bath', Models.User],
  ['route-plans', Models.RoutePlan],
  ['public-api', Models.PublicApi],
]);
