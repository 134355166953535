import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import { Route, Location } from '@models';
import { CONSTANTS } from '@constants';
export abstract class GoogleMapComponent implements OnInit, AfterViewInit {
  protected mapOptions: google.maps.MapOptions;
  protected directionsService: google.maps.DirectionsService;
  protected directionsRenderer: google.maps.DirectionsRenderer;
  protected geocoder: google.maps.Geocoder;
  protected computedDirections: any = {};
  protected mapMarker: google.maps.Marker;
  mapBounds = new google.maps.LatLngBounds();
  autoinit = false;
  isMapInitalised = false;
  private _map: google.maps.Map;

  @ViewChild('googleMap', { static: true })
  googleMapElement: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.autoinit) {
      this.initMap();
    }
  }

  get map(): google.maps.Map {
    if (!this._map) {
      this.initMap();
    }
    return this._map;
  }

  initMap() {
    if (!this.mapOptions) {
      throw new Error('mapOptions is undefined');
    }
    this._map = new google.maps.Map(this.googleMapElement.nativeElement, this.mapOptions);
    this.isMapInitalised = true;
  }

  initDirectionsRenderer() {
    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer();
    this.directionsRenderer.setOptions(CONSTANTS.MAP_CONF.DIRECTIONS_RENDERER_OPTIONS);
    this.directionsRenderer.setMap(this.map);
  }

  initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  showDirections(route: Route): void {
    if (!route.locations || route.locations.length < 2) {
      this.directionsRenderer.setMap(null);
      return;
    }
    this.directionsRenderer.setMap(this.map);
    this.map.setCenter(route.centerLatLng);

    this.directionsService.route(
      {
        origin: route.departureLatLng,
        destination: route.destinationLatLng,
        waypoints: route.waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
        avoidTolls: true,
      },
      (response: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
        if (status.toString() === 'OK') {
          this.directionsRenderer.setDirections(response);
        }
      }
    );
  }

  reverseGeocode(latlng: { lat: number; lng: number }): Promise<Location> {
    return new Promise((success, error) => {
      this.geocoder.geocode({ location: latlng }, function(results, status) {
        if (status.toString() === 'OK') {
          if (results[0]) {
            success(
              new Location({
                address: results[0].formatted_address,
                latitude: latlng.lat,
                longitude: latlng.lng,
              })
            );
          } else {
            error('No results found');
          }
        } else {
          error('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  createMapMarker(lat: number = 0, lng: number = 0, extendedMarkerOptions = {}) {
    if (this.mapMarker) {
      this.mapMarker.setMap(null);
    }
    const markerOptions = Object.assign(
      {
        position: new google.maps.LatLng(lat, lng),
        map: this.map,
        visible: true,
      },
      extendedMarkerOptions
    );
    this.mapMarker = new google.maps.Marker(markerOptions);
  }

  addTrafficLayerOnMap() {
    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(this.map);
  }

  resetBounds() {
    this.mapBounds = new google.maps.LatLngBounds();
  }

  centerMap() {
    this.map.fitBounds(this.mapBounds);
  }

  setZoom(zoom: number) {
    this.map.setZoom(zoom);
  }

  toggleGesture(enable: boolean) {
    this.map.setOptions({
      gestureHandling: enable ? 'cooperative' : 'none',
      zoomControl: enable,
      draggable: enable,
    });
  }
}
