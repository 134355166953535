import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { of, Observable } from 'rxjs';
import { switchMap, delay, catchError, repeat } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';

import { Bus } from '@ion/core/models';
import { IBitfApiResponse, IBitfApiRequest } from '@web/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BusesService extends BitfApiService {
  // mockApiUrl = environment.mockApiUrl;
  // mockApiParser = 'mockDbParser';

  busPollingTime = 3000;

  constructor(protected injector: Injector, private bitfErrorHandler: BitfErrorHandlerService) {
    super('buses', injector);
  }

  busPolling(apiRequest: IBitfApiRequest): Observable<IBitfApiResponse<Bus>> {
    return of(1).pipe(
      switchMap(() => this.getById<Bus>(apiRequest)),
      catchError(e => {
        this.bitfErrorHandler.handleError(e);
        return of({ content: undefined });
      }),
      delay(this.busPollingTime),
      repeat()
    );
  }

  busesPolling(apiRequest: IBitfApiRequest = {}): Observable<IBitfApiResponse<Array<Bus>>> {
    return of(1).pipe(
      switchMap(() => this.get<Bus>(apiRequest)),
      catchError(e => {
        this.bitfErrorHandler.handleError(e);
        return of({ content: [] });
      }),
      delay(this.busPollingTime),
      repeat()
    );
  }
}
