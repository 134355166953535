import { EApiCallStateNames } from '@enums';
import {
  AdminChildrenRegistryListFilterApiRequestPart,
  AdminSearchApiRequestPart,
  AdminDefaultApiCallStateMapper,
  AdminStaffApiCallStateMapper,
} from './api-call-state-parts.barrel';
import { IBitfApiCallState } from '@interfaces';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.CHILDREN_REGISTRY,
    apiRequest: {
      page: 1,
      size: 20,
      embed: ['class'],
      sorting: [{ property: 'lastName', direction: 'ASC' }, { property: 'firstName', direction: 'ASC' }],
    },
    requestParts: [new AdminChildrenRegistryListFilterApiRequestPart(), new AdminSearchApiRequestPart()],
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.CHILDREN_REGISTRY_ITEM,
    apiRequest: {
      embed: ['class($expand=school)', 'parents', 'childrenLists'],
    },
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.CHILDREN_LISTS,
    apiRequest: {
      page: 1,
      size: 20,
      embed: ['children'],
      sorting: [{ property: 'name', direction: 'DESC' }],
    },
    requestParts: [new AdminSearchApiRequestPart()],
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.CHILDREN_LISTS_ITEM,
    apiRequest: {
      embed: ['children', 'school'],
    },
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.SCHOOLS,
    apiRequest: {
      page: 1,
      size: 20,
      embed: ['classes'],
      sorting: [{ property: 'name', direction: 'ASC' }],
    },
    requestParts: [new AdminSearchApiRequestPart()],
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.SCHOOLS_ITEM,
    apiRequest: {
      embed: ['classes'],
    },
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.BUSES,
    apiRequest: {
      page: 1,
      size: 20,
      embed: ['company'],
      sorting: [{ property: 'plate', direction: 'ASC' }],
    },
    requestParts: [new AdminSearchApiRequestPart()],
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.BUSES_ITEM,
    apiRequest: {},
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.STAFF,
    apiRequest: {
      page: 1,
      size: 20,
      sorting: [{ property: 'fullName', direction: 'ASC' }],
    },
    requestParts: [new AdminSearchApiRequestPart()],
    apiCallStateMapper: new AdminStaffApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.ROUTES,
    defaultApiRequest: {
      page: 1,
      size: 20,
      sorting: [{ property: 'name', direction: 'ASC' }],
    },
    requestParts: [new AdminSearchApiRequestPart()],
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.ROUTES_ITEM,
    apiRequest: {
      embed: ['childrenList($expand=children)', 'staff', 'buses', 'routes($expand=locations)'],
    },
    apiCallStateMapper: new AdminDefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
