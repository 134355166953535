import { Injector } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import justClone from 'just-clone';

import { Observable, forkJoin, of, throwError, from } from 'rxjs';
import { map, tap, finalize, filter, catchError, retryWhen, switchMap } from 'rxjs/operators';

import { BitfApiHelper } from './bitf-api.helper';
import { BitfLoggerService } from '@bitf/services/logger/bitf-logger.service';
import { BitfFile } from '@bitf/core/models/bitf-file.model';

import { IBitfApiRequest, IBitfApiResponse, IBitfApiCount, IBitfApiAction } from '@interfaces';
import { AuthService, LoaderService } from '@services';
import { environment } from '@env/environment';

export abstract class BitfApiService {
  protected loaderService: LoaderService;
  protected httpClient: HttpClient;
  protected helper: BitfApiHelper;
  protected isMockActive: boolean;
  protected logTiming = false;
  protected bitfLoggerService: BitfLoggerService;
  protected authService: AuthService;

  constructor(
    protected name: string,
    protected injector: Injector,
    protected mockApiUrl?: string,
    protected mockApiParser?: string
  ) {
    this.loaderService = this.injector.get(LoaderService);
    this.httpClient = this.injector.get(HttpClient);
    this.bitfLoggerService = this.injector.get(BitfLoggerService);
    this.helper = new BitfApiHelper(this.name, this.mockApiUrl, this.mockApiParser);
    this.isMockActive = !!mockApiUrl;
  }

  get<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T[]>> {
    return this.request<T[]>({
      ...requestParams,
      method: 'GET',
    });
  }

  list<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T[]>> {
    if (this.mockApiUrl) {
      requestParams.query = undefined;
    }
    requestParams.path = '/list';
    requestParams.isBodyRaw = true;
    return this.request<T[]>({
      ...requestParams,
      method: this.mockApiUrl ? 'GET' : 'POST',
    });
  }

  getById<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.request<T>({ ...requestParams, method: 'GET' });
  }

  post<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.request<T>({ ...requestParams, method: 'POST' });
  }

  patch<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: 'PATCH',
    });
  }

  put<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.request<T>({ ...requestParams, method: 'PUT' });
  }

  delete(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request<IBitfApiAction>({
      ...requestParams,
      method: 'DELETE',
      modelMapper: 'action',
    });
  }

  bulkDelete(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request<IBitfApiAction>({
      ...requestParams,
      method: 'DELETE',
      path: '/delete-all',
      modelMapper: 'action',
    });
  }

  // FIXME: this should be a single call
  bulkPatch<T>(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiAction>> {
    const observables: Array<Observable<IBitfApiResponse<T>>> = requestParams.body.map((item: T) =>
      this.patch<T>({ ...requestParams, body: item })
    );

    return forkJoin(observables).pipe(
      map((allResponses: Array<IBitfApiResponse<T>>) => {
        const success = !!allResponses.filter(response => !!response.content).length;
        return { content: { success } } as IBitfApiResponse<IBitfApiAction>;
      })
    );
  }

  count(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<IBitfApiCount>> {
    return this.request<IBitfApiCount>({
      ...requestParams,
      method: 'GET',
      count: true,
      modelMapper: 'count',
    });
  }

  // METHODS to call related entities ====================================================
  getRel<T>(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: 'GET',
    });
  }

  getRels<T>(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<T[]>> {
    return this.request<T[]>({
      ...requestParams,
      method: 'GET',
    });
  }

  getRelById<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: 'GET',
    });
  }

  postRel<T>(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: 'POST',
    });
  }

  putRel<T>(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: 'PUT',
    });
  }

  patchRel<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: 'PATCH',
    });
  }

  deleteRel(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request<IBitfApiAction>({
      ...requestParams,
      method: 'DELETE',
      modelMapper: 'action',
    });
  }

  countRel(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiCount>> {
    return this.request<IBitfApiCount>({
      ...requestParams,
      method: 'GET',
      modelMapper: 'count',
      count: true,
    });
  }

  // ADD and remove already existing entity as relations
  linkRel<T>(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request<IBitfApiAction>({
      ...requestParams,
      method: 'POST',
      linkUnlinkRel: true,
      modelMapper: 'action',
    });
  }

  unLinkRel<T>(requestParams: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request<IBitfApiAction>({
      ...requestParams,
      method: 'DELETE',
      linkUnlinkRel: true,
      modelMapper: 'action',
    });
  }

  action<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.request<T>({
      ...requestParams,
      method: requestParams.body === undefined ? 'GET' : 'POST',
    });
  }

  upload<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    requestParams.file.resetUploadState();
    requestParams.body = requestParams.body || {};

    Object.assign(requestParams.body, requestParams.file.bodyData);
    Object.assign(requestParams.body, {
      [requestParams.fileFormFieldName]: requestParams.file.fileObject,
    });
    requestParams = {
      method: 'POST',
      bodyParser: 'formData',
      isBodyRaw: true,
      reportProgress: true,
      observe: 'events',
      body: requestParams.body,
      ...requestParams,
    };

    return this.fetch(requestParams).pipe(
      tap(event => {
        this.parseUploadState(event, requestParams.file);
      }),
      filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
      map(envelope => {
        return this.helper.mapEnvelope<T>(envelope, requestParams);
      })
    );
  }

  uploadMultiple<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>[]> {
    const fileUploadRequests: Observable<any>[] = requestParams.files.map((fileItem: BitfFile) => {
      const newRequestParams = {
        ...justClone(requestParams),
        file: fileItem,
      };

      return this.upload<T>(newRequestParams).pipe(
        catchError((errors: any) => {
          fileItem.uploadError = errors;
          fileItem.hasUploadErrors = true;
          fileItem.isUploading = false;
          // NOTE: we don't want to break the forkJoin and prevent the upload of the other files
          // the error is stored in the file object
          return of({
            content: undefined,
          } as IBitfApiResponse<T>);
        })
      );
    });
    return forkJoin(fileUploadRequests);
  }

  private parseUploadState(event: HttpEvent<any>, file: BitfFile) {
    switch (event.type) {
      case HttpEventType.Sent:
        file.isUploading = true;
        break;
      case HttpEventType.UploadProgress:
        file.uploadedPercentage = Math.round((100 * event.loaded) / event.total);
        // NOTE: start a little bit before
        if (file.uploadedPercentage >= 98) {
          file.isProcessing = true;
        }
        break;
      case HttpEventType.Response:
        file.isProcessing = false;
        file.uploadedPercentage = 100;
        file.isUploaded = true;
        file.isUploading = false;
        break;
    }
    return event;
  }

  /**
   * This is an Api helper that will parse the request and response, calling the this.apiUrl
   * as base endpoint
   */
  request<T>(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<T>> {
    return this.fetch(requestParams).pipe(
      map((envelope: HttpResponse<IBitfApiResponse<any>>) =>
        this.helper.mapEnvelope<T>(envelope, requestParams)
      )
    );
  }

  /**
   * This is a generic Api helper usefull to do httpClient calls to arbitrary endpoint without parsing the
   * response. Note taht this is parsing the request, so this method can call only application API's
   */
  fetch(requestParams: IBitfApiRequest = {}): Observable<any> {
    const parsedRequestParams = this.helper.parseRequestParams(requestParams);
    let apiCall: Observable<any>;
    const start = Date.now();
    switch (requestParams.method) {
      case 'GET':
        apiCall = this.httpClient[requestParams.method.toLocaleLowerCase()](
          parsedRequestParams.path,
          parsedRequestParams.options
        );
        break;
      case 'DELETE':
        if (parsedRequestParams.body) {
          apiCall = this.httpClient.request(
            requestParams.method.toLocaleLowerCase(),
            parsedRequestParams.path,
            {
              body: parsedRequestParams.body,
            }
          );
        } else {
          apiCall = this.httpClient[requestParams.method.toLocaleLowerCase()](
            parsedRequestParams.path,
            parsedRequestParams.options
          );
        }

        break;
      case 'POST':
      case 'PUT':
      case 'PATCH':
        apiCall = this.httpClient[requestParams.method.toLocaleLowerCase()](
          parsedRequestParams.path,
          parsedRequestParams.body,
          parsedRequestParams.options
        );
        break;
    }
    return apiCall.pipe(
      // This retry will be used in case we have renewToken mechanism for the auth system used
      // This will retry only one time, if the renewToken resolve the promise otherwise it will forward
      // the error
      retryWhen(retryEvent =>
        retryEvent.pipe(
          switchMap(errorEvent => {
            // NOTE: doing this in the constructor will lead errors since the authService use this class
            const authService = this.injector.get(AuthService);
            if (errorEvent instanceof HttpErrorResponse && errorEvent.status === 401) {
              return from(authService.renewToken()).pipe(
                catchError(() => {
                  // NOTE: forward the HttpErrorResponse not the renewToken one
                  throw errorEvent;
                })
              );
            } else {
              throw errorEvent;
            }
          })
        )
      ),
      catchError(error => {
        error.method = requestParams.method;
        error.requestBody = requestParams.body;
        error.queryParams = parsedRequestParams.options['params'];
        throw error;
      }),
      tap(() => {
        if (requestParams.logTiming || this.logTiming) {
          const elapsed = Date.now() - start;
          const sendQueryParams = environment.loggerConfig.sendQueryParams;
          const sendRequestBody = environment.loggerConfig.sendRequestBody;
          this.bitfLoggerService.log({
            level: 'TIME',
            elapsed,
            title: `TIME - ${elapsed}ms - ${requestParams.method} ${parsedRequestParams.path}`,
            method: requestParams.method,
            url: parsedRequestParams.path,
            queryParams: sendQueryParams ? JSON.stringify(parsedRequestParams.options['params']) : undefined,
            body:
              parsedRequestParams.body && sendRequestBody
                ? JSON.stringify(parsedRequestParams.body)
                : undefined,
          });
        }
      }),
      finalize(() => !requestParams.disableHideLoader && this.loaderService.hide())
    );
  }
}
