import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';

import { BitfRedirectComponent } from '@bitf/core/components/functional/redirect';

import { AdminSharedModule } from './../shared/admin-shared.module';
import { AdminLoginModule } from './../modules/login/admin-login.module';
import { CommonCoreModule } from '@common/core/common-core.module';

import { throwIfAlreadyLoaded } from './module-config';
import { AdminHeaderComponent } from './components/header/admin-header.component';
import { AdminMenuComponent } from './components/menu/admin-menu.component';

import { AdminNotFoundComponent } from './components/not-found/admin-not-found.component';

const EXPORTS_COMPONENTS = [AdminNotFoundComponent, AdminHeaderComponent];
const COMPONENTS = [AdminMenuComponent, BitfRedirectComponent];

@NgModule({
  imports: [CommonCoreModule, RouterModule, AdminSharedModule.forRoot(), AdminLoginModule, PortalModule],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  providers: [],
})
export class AdminCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AdminCoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'AdminCoreModule');
  }
}
