import { Component, OnInit, Input, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EBitfCloseEventStatus } from '@enums';
import { IBitfCloseEvent } from '@interfaces';

import { GoogleMapComponent } from '../google-map/google-map.component';
import { CONSTANTS } from '@constants';
import { Child } from '@models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cm-child-last-position',
  templateUrl: './child-last-position.component.html',
  styleUrls: ['./child-last-position.component.scss'],
})
export class ChildLastPositionComponent extends GoogleMapComponent implements OnInit {
  child: Child;

  constructor(
    public dialogRef: MatDialogRef<ChildLastPositionComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public translateService: TranslateService
  ) {
    super();
    this.child = dialogData.child;
  }

  childPositionDescription: string;

  ngOnInit() {
    if (!this.isMapInitalised) {
      this.childPositionDescription = this.translateService.instant(
        'ADMIN.DIALOG.LABEL.CHILD_LAST_POSITION_DESCRIPTION',
        {
          name: this.child.fullName,
        }
      );

      this.mapOptions = {
        ...CONSTANTS.MAP_CONF.DEFAULT_OPTIONS,
        center: this.child ? this.child.location.googleLatLng : CONSTANTS.MAP_CONF.DEFAULT_OPTIONS.center,
      };
      this.initMap();
      this.initDirectionsRenderer();
      this.createMapMarker(this.child.location.latitude, this.child.location.longitude, {
        icon: 'assets/icons/pin-person-circle.svg',
      });
    }
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
    } as IBitfCloseEvent<any>);
  }
}
