import { MatDialogRef } from '@angular/material/dialog';
import { EBitfCloseEventStatus } from '@enums';

export abstract class AdminDialogComponent<T> {
  public abstract dialogRef: MatDialogRef<T>;

  onClose() {
    this.dialogRef.close({
      data: null,
      status: EBitfCloseEventStatus.CANCEL,
    });
  }
}
