import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { timer, Subscription } from 'rxjs';
import { debounce, filter } from 'rxjs/operators';

import { EApiCallStateNames, EBitfApiCallStateActions, EApiRequestPartKeys } from '@enums';

import { IAdminSearchSettings } from '@interfaces';
import { ApiCallStateService } from '@services';
import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

@Component({
  selector: 'cm-admin-search',
  templateUrl: './admin-search.component.html',
  styleUrls: ['./admin-search.component.scss'],
})
export class AdminSearchComponent implements OnInit, OnDestroy {
  @Input()
  placeholder: string;

  @Input()
  minLengthOfSearchKeyword = 3;

  @Input()
  apiCallStateName: EApiCallStateNames;

  @Input()
  searchSettings: IAdminSearchSettings;

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();

  filter: BitfApiRequestPart;
  subscription: Subscription = new Subscription();
  searchInput: FormControl = new FormControl();

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    this.initFilter();
    this.subscription.add(
      this.searchInput.valueChanges
        .pipe(
          debounce(() => timer(600)),
          filter(value => value.length >= this.minLengthOfSearchKeyword || value.length === 0)
        )
        .subscribe(value => {
          if (this.apiCallStateName === undefined) {
            this.search.emit(value);
            return;
          }
          this.updateFilter();
        })
    );
  }

  initFilter() {
    if (this.apiCallStateName === undefined) {
      return;
    }
    this.filter = this.apiCallStateService.getRequestPart(this.apiCallStateName, EApiRequestPartKeys.SEARCH);
    if (this.filter.formValue) {
      this.searchInput.patchValue(this.filter.formValue.keyword);
    }
  }

  updateFilter() {
    this.filter.data = {
      ...this.filter.data,
      keyword: this.searchInput.value,
      searchSettings: this.searchSettings,
    };
    this.filter.formValue = {
      keyword: this.searchInput.value,
    };
    this.apiCallStateService.dispatchApiCallState(EBitfApiCallStateActions.UPDATE, this.apiCallStateName);
  }

  reset() {
    this.searchInput.setValue('');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
