import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@common/services/guards';
import { AdminNotFoundComponent } from './core/components/not-found/admin-not-found.component';
import { AdminSignInComponent } from './modules/login/sign-in/admin-sign-in.component';
import { SignOutComponent } from '@common/core/components/sign-out/sign-out.component';
import { BusTrackerComponent } from './shared/components/bus-tracker/bus-tracker.component';

const publicRoutesData = {
  showHeader: false,
};

const privateRoutesData = {
  showHeader: true,
};

const routes: Routes = [
  { path: '', redirectTo: 'schools', pathMatch: 'full' },
  {
    path: 'sign-in',
    component: AdminSignInComponent,
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
  },
  {
    path: 'buses',
    loadChildren: () => import('./modules/bus/admin-bus.module').then(m => m.AdminBusModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'children',
    loadChildren: () =>
      import('./modules/children-registry/admin-children-registry.module').then(
        m => m.AdminChildrenRegistryModule
      ),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'routes',
    loadChildren: () => import('./modules/routes/admin-routes.module').then(m => m.AdminRoutesModule),
    data: { ...privateRoutesData },
  },
  {
    path: 'children-lists',
    loadChildren: () =>
      import('./modules/children-lists/admin-children-lists.module').then(m => m.AdminChildrenListsModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'staff',
    loadChildren: () => import('./modules/staff/admin-staff.module').then(m => m.AdminStaffModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'schools',
    loadChildren: () => import('./modules/school/admin-school.module').then(m => m.AdminSchoolModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'public',
    children: [
      {
        component: BusTrackerComponent,
        path: 'buses/:busCompanyId',
      },
    ],
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./../../common/libs/bitforce/modules/changelog/bitf-changelog.module').then(
        m => m.BitfChangelogModule
      ),
  },
  {
    path: '**',
    component: AdminNotFoundComponent,
    data: { ...publicRoutesData },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AdminAppRoutingModule {}
