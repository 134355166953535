import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';

import { CONSTANTS } from '@constants';
import { StorageService } from '@services';
import { IBitfLanguage } from './bitf-dynamic-locale.interface';

@Injectable({
  providedIn: 'root',
})
export class BitfDynamicLocaleService {
  private _locale: IBitfLanguage;

  constructor(private translateService: TranslateService, private storageService: StorageService) {}

  init() {
    this.setLocaleNoSaveToStorage(this.defaultLocale);
  }

  get locale() {
    return this._locale;
  }

  get defaultLocale(): IBitfLanguage {
    const fromStorage = this.storageService.data.selectedLanguage;
    const navigatorLanguage = navigator.language.split('-')[0].toLowerCase();
    const fromNavigator = CONSTANTS.SUPPORTED_LANGUAGES.find(
      lang => lang.code.toLowerCase() === navigatorLanguage
    );
    const result = fromStorage || (fromNavigator ? fromNavigator : CONSTANTS.DEFAULT_LANGUAGE);
    result.code = result.code.toLowerCase();

    return result;
  }

  async setLocale(locale: IBitfLanguage): Promise<void> {
    await this.setLocaleNoSaveToStorage(locale);
    this.storageService.data = { selectedLanguage: this._locale };
  }

  async setLocaleNoSaveToStorage(locale: IBitfLanguage): Promise<void> {
    const localeCode = locale.code.toLowerCase();
    const translateP = this.translateService.use(localeCode).toPromise();
    const localeData = await this.loadLocale(localeCode);
    registerLocaleData(localeData);
    await translateP;

    // _locale must be set at the end, because it is read from pipes and locale data must be already loaded
    this._locale = locale;
  }

  private async loadLocale(locale: string): Promise<unknown> {
    const module = await import('../../../../../../locale/' + locale.toLowerCase());
    return module.default;
  }
}
