// import { IApiParser } from '@bitf/api';
import { AppRequestMapper, AppResponseMapper, AppPathBuilder, AppEnvelopeMapper } from '@common/parsers';
import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbEnvelopeMapper,
  BitfMockDbResponseMapper,
} from '@bitf/parsers/mock-db-parser';

import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  authService: {
    tokenAlertTresholdMinutes: 5,
  },
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: ['401', '404', '500'] },
  sockets: {},
  parsers: {
    defaultParser: 'appParser',
    parserStrategies: {
      appParser: {
        namespace: '@sintra',
        customHeaders: {
          Prefer: 'odata.include-annotations=*',
        },
        uiMessageKey: 'messages',
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};
