<div class="cm-fixed-viewport bus-tracker-map row">
  <div class="col p-0 h-100">
    <button mat-fab
      class="bus-list-button"
      *ngIf="!isDesktop"
      (click)="openBusList()">
      <mat-icon>list</mat-icon>
    </button>

    <div #googleMap
      class="buses-position-google-map"></div>
  </div>

  <div class="col-auto"
    *ngIf="isDesktop">
    <cm-bus-tracker-list [buses]="buses"
      [inSidenav]="false"
      (busMouseEnter)="onbusMouseEnter($event)"
      (busMouseLeave)="onbusMouseLeave($event)"></cm-bus-tracker-list>
  </div>
</div>