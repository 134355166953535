import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from './bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class BitfGenericService extends BitfApiService {
  constructor(protected injector: Injector) {
    super('', injector);
  }
}
