import { IBitfLanguage } from '@interfaces';

export class BitfStorage {
  selectedLanguage?: IBitfLanguage;
  redirectUrl?: string;

  constructor(data: Partial<BitfStorage> = {}) {
    Object.assign(this, data);
  }
}
