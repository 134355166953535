import { OnInit, Injector } from '@angular/core';

import { EBitfCloseEventStatus } from '@enums';
import { IMenuItem, IBitfApiResponse } from '@interfaces';
import { User } from '@models';
import { UsersService } from '@common/services';
import { BitfGenericService } from '@bitf/services/api/bitf-generic.service';
import { BitfMatSidenavService } from '@bitf/services/sidenav/material/bitf-mat-sidenav.service';
import { AuthService, StoreService } from '@services';
import { APP_VERSION, BUILD_DATE } from '@env/version';
import { environment } from '@env/environment';

export abstract class CommonMenuComponent implements OnInit {
  abstract menuItems: Array<IMenuItem>;
  user: User;

  mSidenavService: BitfMatSidenavService;
  authService: AuthService;
  usersService: UsersService;
  storeService: StoreService;
  genericService: BitfGenericService;
  appVersion: string;

  constructor(public injector: Injector) {
    this.mSidenavService = this.injector.get(BitfMatSidenavService);
    this.authService = this.injector.get(AuthService);
    this.usersService = this.injector.get(UsersService);
    this.storeService = this.injector.get(StoreService);
    this.genericService = this.injector.get(BitfGenericService);
  }

  ngOnInit() {
    this.user = this.storeService.store.user;
    this.loadApiAppVersion();
  }

  closeSidenav() {
    this.mSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }

  loadApiAppVersion() {
    this.genericService
      .request({
        method: 'GET',
        path: 'version',
        disableHideLoader: true,
      })
      .subscribe((response: IBitfApiResponse<{ revision: string; version: string }>) => {
        this.appVersion = `FE: ${APP_VERSION} - BE: v${response.content.version} - Built at: ${BUILD_DATE}`;
        if (!environment.production) {
          this.appVersion += ` - ${environment.name}`;
        }
      });
  }
}
