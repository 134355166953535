import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'bitf-add-to-home-prompt',
  templateUrl: './bitf-add-to-home-prompt.component.html',
  styleUrls: ['./bitf-add-to-home-prompt.component.scss'],
})
export class BitfAddToHomePromptComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BitfAddToHomePromptComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {}

  prompt(event) {
    this.dialogData.event.prompt();
  }
}
