import { IBitfApiRequest, IBitfPathBuilder } from '@interfaces';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
export abstract class BitfODataPathBuilder implements IBitfPathBuilder {
  constructor() {}

  build(requestParams: IBitfApiRequest = {}): string {
    let path = '';
    const id = requestParams.id || bitfGetProp(requestParams, 'body', 'id');
    if (id) {
      path += `(${id})`;
    }
    const action = requestParams.action;
    if (action) {
      path += `${action}`;
      const actionParams = requestParams.actionParams;
      if (actionParams) {
        path += `(${actionParams})`;
      } else {
        path += `()`;
      }
    }
    if (requestParams.relation) {
      path += `/${requestParams.relation}`;

      const relationId = bitfGetProp(requestParams, 'body', 'id') || requestParams.relationId;

      if (relationId) {
        path += `(${relationId})`;
      }

      if (requestParams.linkUnlinkRel) {
        path += '/$ref';
      }
    }
    return path;
  }
}
