import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MATERIAL_MODULES, CDK_MODULES } from './admin-material-modules';
import { CommonSharedModule } from '@common/shared/common-shared.module';

import { MAT_DATE_LOCALE } from '@angular/material/core';

// tslint:disable-next-line:max-line-length
import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
// tslint:disable-next-line:max-line-length
import { BitfAddToHomePromptComponent } from '@bitf/core/components/ui/add-to-home-prompt';
// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';

// tslint:disable-next-line:max-line-length
import { AdminCrudPresenterComponent } from './components/crud-presenter/admin-crud-presenter.component';
import { AdminSearchComponent } from './components/search/admin-search.component';
// tslint:disable-next-line:max-line-length
import { AdminClassAutocompleteComponent } from './components/class-autocomplete/admin-class-autocomplete.component';
import { AdminDialogFilterComponent } from './components/admin-dialog-filter/admin-dialog-filter.component';
import { PortalModule } from '@angular/cdk/portal';
import { AdminFiltersChipsComponent } from './components/filters-chips/admin-filters-chips.component';
import { AdminLocationSearchComponent } from './components/location-search/admin-location-search.component';
import { ChildLastPositionComponent } from '@common/shared/child-last-position/child-last-position.component';
import { BusTrackerComponent } from './components/bus-tracker/bus-tracker.component';
import { BusTrackerListComponent } from './components/bus-tracker-list/bus-tracker-list.component';
import { DatePipe } from '@angular/common';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES, PortalModule];
const SHARED_COMPONENTS = [
  BitfAddToHomePromptComponent,
  BitfMatOkCancelDialogComponent,
  BitfLoaderComponent,
  AdminCrudPresenterComponent,
  AdminSearchComponent,
  AdminClassAutocompleteComponent,
  AdminDialogFilterComponent,
  AdminFiltersChipsComponent,
  AdminLocationSearchComponent,
  ChildLastPositionComponent,
  BusTrackerComponent,
  BusTrackerListComponent,
];
const SHARED_DIRECTIVES = [];
const PROVIDERS = [DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }];
@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class AdminSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AdminSharedModule,
      providers: [],
    };
  }
}
