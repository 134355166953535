import { SuperModel } from './super-model.model';
import { RouteLocation } from './route-location.model';
import { ERouteType } from '@enums';

export class Route extends SuperModel {
  type: ERouteType;
  startTime: string;
  endTime: string;
  locations: RouteLocation[] = [];

  constructor(data: any = {}) {
    super(data);
    if (data.locations) {
      this.locations = data.locations.map(location => new RouteLocation(location));
    } else {
      this.locations = [];
    }
    if (data.startTime) {
      this.startTime = this.parseTimeFormat(data.startTime);
    }
    if (data.endTime) {
      this.endTime = this.parseTimeFormat(data.endTime);
    }
  }

  get departureLatLng() {
    const location = this.locations[0];
    return location && location.googleLatLng;
  }

  get destinationLatLng() {
    const location = this.locations[this.locations.length - 1];
    return location && location.googleLatLng;
  }

  get centerLatLng() {
    const location = this.locations[Math.ceil(this.locations.length / 2)];
    return location && location.googleLatLng;
  }

  get waypoints(): google.maps.DirectionsWaypoint[] {
    return this.locations.slice(1, this.locations.length - 1).map((location: RouteLocation) => {
      return {
        location: location.googleLatLng,
        stopover: false,
      } as google.maps.DirectionsWaypoint;
    });
  }

  get serialised() {
    const locations = this.locations && this.locations.map(location => location.serialised);
    return {
      locations,
      id: this.id,
      type: this.type,
      startTime: locations && locations.length ? locations[0].time : null,
      endTime: locations && locations.length ? locations[locations.length - 1].time : null,
    };
  }
}
