import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IBitfLoaderAction } from '@interfaces';
import { EBitfLoaderAction } from '@enums';

import { environment } from '@env/environment';

const DELAY = 600;
const MIN_TIMEOUT = 5000;

export abstract class BitfLoaderService {
  onLoaderChange: Subject<IBitfLoaderAction> = new Subject<IBitfLoaderAction>();
  isLoading: boolean;
  timeoutId: any;
  hideTimeout: number;
  hideTimeoutId: any;
  showCallsCounter = 0;

  constructor() {
    this.hideTimeout = DELAY + MIN_TIMEOUT;
    if (environment.httpRetryConfig.enabled) {
      const verbs = environment.httpRetryConfig.verbs;
      const timeout = Object.keys(verbs).reduce((accumulator: number, key: string) => {
        if (verbs[key].timeout) {
          const maxRetries = verbs[key].maxRetries || 1;
          accumulator = Math.max(accumulator, verbs[key].timeout * maxRetries);
        }
        return accumulator;
      }, MIN_TIMEOUT);

      this.hideTimeout = timeout + DELAY;
    }
  }

  show(data?: any) {
    this.clearTimeouts();
    this.showCallsCounter += 1;
    this.timeoutId = setTimeout(() => {
      this.onLoaderChange.next({ action: EBitfLoaderAction.SHOW, data } as IBitfLoaderAction);
    }, DELAY);
    if (!data || (data && !data.disableHideLoader)) {
      this.hideTimeoutId = setTimeout(() => {
        this.showCallsCounter = 0;
        this.hide();
      }, this.hideTimeout);
    }
  }

  hide() {
    this.showCallsCounter -= 1;
    if (this.showCallsCounter <= 0) {
      this.forceHide();
    }
  }

  forceHide() {
    this.clearTimeouts();
    this.onLoaderChange.next({ action: EBitfLoaderAction.HIDE } as IBitfLoaderAction);
    this.showCallsCounter = 0;
  }

  private clearTimeouts() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.hideTimeoutId);
  }
}
