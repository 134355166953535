import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

import { CONSTANTS } from '@constants';
import { BusesService, PublicApiService, StoreService } from '@services';
import { Bus, PublicApi } from '@models';
import { IBitfApiResponse, IBitfCloseEvent } from '@interfaces';

import { GoogleMapComponent } from '@common/shared/google-map/google-map.component';
// tslint:disable-next-line: max-line-length
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { BusTrackerListComponent } from '../bus-tracker-list/bus-tracker-list.component';
import { EBitfCloseEventStatus, EStoreActions, EAdminStoreActions } from '@admin/enums';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cm-admin-bus-tracker',
  templateUrl: './bus-tracker.component.html',
  styleUrls: ['./bus-tracker.component.scss'],
})
export class BusTrackerComponent extends GoogleMapComponent implements OnInit, OnDestroy {
  busCompanyId: any;

  mapOptions: google.maps.MapOptions;
  markersMap = new Map<number, { marker: google.maps.Marker; infoWindow: google.maps.InfoWindow }>();
  subscription: Subscription = new Subscription();
  buses: Bus[] = [];
  isDesktop = false;

  constructor(
    private buesesService: BusesService,
    private publicApiService: PublicApiService,
    private datePipe: DatePipe,
    private sidenavService: BitfMatSidenavService,
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService
  ) {
    super();
  }

  ngOnInit() {
    // NOTE: start following all buses
    this.storeService.setStore(store => {
      store.isFollowingAllDashboardBuses = true;
      store.dashboardSelectedBus = undefined;
    }, EAdminStoreActions.UPDATE_BUS_TRACKER);

    this.mapOptions = {
      ...CONSTANTS.MAP_CONF.DEFAULT_OPTIONS,
      zoomControl: false,
      draggable: false,
      draggableCursor: 'default',
      styles: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
      ],
    };
    this.initMap();
    this.addTrafficLayerOnMap();
    // this.addMapCallbackListeners();

    if (this.storeService.store.user) {
      this.busCompanyId = this.storeService.store.user.companyId;
      this.initBuses();
    } else {
      this.busCompanyId = this.activatedRoute.snapshot.paramMap.get('busCompanyId');
      if (this.busCompanyId) {
        this.initBusesWithPublicApi();
      }
    }

    this.isDesktop = this.storeService.store.activeBreakpoints.isDesktop;
    this.storeService.selectStore(EStoreActions.BREACKPOINT).subscribe(storeEvent => {
      this.isDesktop = storeEvent.store.activeBreakpoints.isDesktop;
    });

    this.storeService.selectStore(EStoreActions.UPDATE_BUS_TRACKER).subscribe(storeEvent => {
      this.toggleGesture(
        !storeEvent.store.isFollowingAllDashboardBuses && !storeEvent.store.dashboardSelectedBus
      );

      this.handleCenterMapEvent();
    });
  }

  openBusList() {
    const trackerListRef = this.sidenavService.open({
      component: BusTrackerListComponent,
      componentData: {
        inSidenav: true,
        buses: this.buses,
      },
    });

    trackerListRef.subscribe((result: IBitfCloseEvent<Bus | Bus[]>) => {
      if (result && result.status === EBitfCloseEventStatus.OK) {
        if ((result.data as Bus[]).length) {
          this.updateBusTracker(undefined, true);
          this.centerMapByBusesLocation(this.buses);
        } else {
          this.updateBusTracker(result.data as Bus, false);
          this.centerMapByBusesLocation([result.data as Bus]);
        }
      }
    });
  }

  onFollowUnfollowBuses() {
    this.centerMapByBusesLocation(this.buses);
  }

  onbusMouseEnter(bus: Bus) {
    this.toggleMarkerInfoWindow(bus.id, 'show');
  }

  onbusMouseLeave(bus: Bus) {
    this.toggleMarkerInfoWindow(bus.id, 'hide');
  }

  private initBuses() {
    this.subscription.add(
      // TODO { embed: ['driver'] }
      this.buesesService.busesPolling().subscribe((response: IBitfApiResponse<Array<Bus>>) => {
        this.parseBusesResponse(response.content);
      })
    );
  }

  private initBusesWithPublicApi() {
    this.subscription.add(
      this.publicApiService
        .busesPolling({
          actionParams: `companyId=${this.busCompanyId}`,
          embed: ['driver'],
        })
        .subscribe((response: IBitfApiResponse<PublicApi>) => {
          this.parseBusesResponse(response.content.buses);
        })
    );
  }

  private parseBusesResponse(buses: Bus[]) {
    this.buses = buses.filter(bus => bus.location.latitude);

    this.buses.forEach((bus: Bus) => {
      if (!this.markersMap.has(bus.id)) {
        if (bus.location.latitude) {
          this.addBusMarker(bus);
        }
      } else {
        this.updateBusMarker(bus);
      }
    });

    // Update the store dashboardSelectedBus
    const dashboardSelectedBus = this.buses.find(
      b =>
        b.id ===
        (this.storeService.store.dashboardSelectedBus && this.storeService.store.dashboardSelectedBus.id)
    );
    if (dashboardSelectedBus) {
      this.storeService.store.dashboardSelectedBus = dashboardSelectedBus;
    }

    // NOTE remove deleted buses
    for (const [busId, _] of this.markersMap) {
      const isBusInResponse = buses.find((b: Bus) => b.id === busId);
      if (!isBusInResponse) {
        this.markersMap.delete(busId);
      }
    }

    // NOTE: this will create the live tracking since is inside a polling callback
    this.handleCenterMapEvent();
  }

  private centerMapByBusesLocation(buses: Bus[]) {
    this.resetBounds();
    buses.forEach(bus => {
      if (bus.location.latitude) {
        this.mapBounds.extend(bus.location.googleLatLng);
      }
    });
    this.centerMap();

    // Give more space to the map if we are tracking a single bus
    if (buses.length === 1) {
      this.map.setZoom(this.map.getZoom() - 5);
    }
    if (!this.storeService.store.isFollowingAllDashboardBuses) {
    }
  }

  private addBusMarker(bus: Bus) {
    const infoWindow = new google.maps.InfoWindow({
      content: this.getInfoWindowContent(bus),
    });

    const marker = new google.maps.Marker({
      icon: '/assets/icons/bus_icon.svg',
      draggable: false,
      position: bus.location.googleLatLng,
      map: this.map,
      visible: true,
      title: `${bus.dashboardLabel || bus.id}`,
    });

    // infoWindow.open(this.map, marker);
    google.maps.event.addListener(marker, 'click', () => {
      const dashboardSelectedBus = this.buses.find(b => b.id === bus.id);
      this.updateBusTracker(dashboardSelectedBus, false);
      this.centerMapByBusesLocation([bus]);
    });
    this.markersMap.set(bus.id, { marker, infoWindow });
  }

  private updateBusMarker(bus: Bus) {
    const { marker, infoWindow } = this.markersMap.get(bus.id);
    marker.setPosition(bus.location.googleLatLng);
    infoWindow.setContent(this.getInfoWindowContent(bus));
  }

  private getInfoWindowContent(bus: Bus) {
    return `${bus.plate} / ${this.datePipe.transform(bus.location.createdAt, 'dd-MM-yyyy HH:mm:ss')}`;
  }

  private toggleMarkerInfoWindow(busId: number, action = 'show') {
    if (!this.storeService.store.dashboardSelectedBus) {
      const { marker, infoWindow } = this.markersMap.get(busId);
      if (infoWindow) {
        if (action === 'show') {
          infoWindow.open(this.map, marker);
        } else {
          infoWindow.close();
        }
      }
    }
  }

  // Maybe useful when to disable for x seconds the center map function
  // private addMapCallbackListeners() {
  //   google.maps.event.addListener(this.map, 'zoom_changed', event => {
  //   });
  // }

  private updateBusTracker(bus: Bus | undefined, isFollowingAllBuses: boolean) {
    this.storeService.setStore(store => {
      store.dashboardSelectedBus = bus;
      store.isFollowingAllDashboardBuses = isFollowingAllBuses;
    }, EAdminStoreActions.UPDATE_BUS_TRACKER);
  }

  private handleCenterMapEvent() {
    if (!this.shouldCenterMap()) {
      return;
    }

    if (this.storeService.store.dashboardSelectedBus) {
      this.centerMapByBusesLocation([this.storeService.store.dashboardSelectedBus]);
    } else {
      this.centerMapByBusesLocation(this.buses);
    }
  }

  private shouldCenterMap() {
    if (
      !this.storeService.store.isFollowingAllDashboardBuses &&
      !this.storeService.store.dashboardSelectedBus
    ) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
