import { IBitfApiCallState } from '@bitf/interfaces';
import { BitfApiRequestPart } from './bitf-api-request-part';
import { EBitfApiRequestPartsNames } from '@enums';

export abstract class BitfApiCallStateMapper {
  computedApiRequestParts: { [key in EBitfApiRequestPartsNames]: any } = {
    headers: [],
    queryParams: [],
    actionParams: [],
    query: [],
    search: [],
    filter: [],
    embed: [],
    computedFields: [],
    sorting: [],
    data: [],
    pagination: { page: undefined, size: undefined, paginationInfo: undefined },
  };

  abstract buildApiRequest(apiCallState: IBitfApiCallState): void;

  mapApiCallState(apiCallState: IBitfApiCallState) {
    apiCallState.apiRequest = apiCallState.apiRequest || {};
    this.reset();

    if (Array.isArray(apiCallState.requestParts)) {
      apiCallState.requestParts.forEach((requestPart: BitfApiRequestPart) => {
        requestPart.build();
        if (requestPart.part) {
          if (requestPart.partName === 'pagination') {
            this.computedApiRequestParts[requestPart.partName] = requestPart.part;
          } else {
            const computedPart = this.computedApiRequestParts[requestPart.partName] as any[];
            computedPart.push(requestPart.part);
          }
        }
      });
    }

    this.buildApiRequestPagination(apiCallState);
    this.buildApiRequest(apiCallState);
  }

  protected buildApiRequestPagination(apiCallState: IBitfApiCallState): void {
    const pagination = this.computedApiRequestParts.pagination;
    if (pagination.page !== undefined || pagination.size !== undefined) {
      apiCallState.apiRequest.page = this.computedApiRequestParts.pagination.page;
      apiCallState.apiRequest.size = this.computedApiRequestParts.pagination.size;
    }
    if (pagination.paginationInfo !== undefined) {
      apiCallState.apiRequest.paginationInfo = this.computedApiRequestParts.pagination.paginationInfo;
    }
  }

  protected reset() {
    Object.keys(this.computedApiRequestParts).forEach(requestPartName => {
      this.computedApiRequestParts[requestPartName] = [];
    });
  }

  protected assignApiRequestValuesAsArray(
    apiCallState: IBitfApiCallState,
    property: EBitfApiRequestPartsNames
  ) {
    apiCallState.apiRequest[property] = [];
    this.computedApiRequestParts[property].forEach(el => {
      if (Array.isArray(el)) {
        apiCallState.apiRequest[property].push(...el);
      } else {
        apiCallState.apiRequest[property].push(el);
      }
    });
  }

  protected assignApiRequestValuesAsObject(
    apiCallState: IBitfApiCallState,
    property: EBitfApiRequestPartsNames
  ) {
    apiCallState.apiRequest[property] = {};
    this.computedApiRequestParts[property].forEach(el => {
      if (Array.isArray(el)) {
        el.forEach(qp => (apiCallState.apiRequest[property][qp.name] = [qp.value]));
      } else {
        Object.assign(apiCallState.apiRequest[property], el);
      }
    });
  }
}
