import { SuperModel } from './super-model.model';

import { getDateWithoutTime } from '@bitf/utils/bitf-dates.utils';

import {
  EChildPositionIcon,
  EChildPositionIconColor,
  EChildPositionTooltip,
  EGender,
  EUserRoles,
  EChildPosition,
} from '@enums';
import { User } from './user.model';
import { Location } from './location.model';
import { Class } from './class.model';
import { ChildrenList, Beacon } from '@models';

export class Child extends SuperModel {
  firstName: string;
  lastName: string;
  gender: EGender;
  birthDate: Date;
  fiscalCode: string;
  nation: string;
  province: string;
  city: string;
  pickUpLocationId: number;
  dropOffLocationId: number;
  classId: number;
  userId: number;
  message: string;
  childrenLists: ChildrenList[];
  location: Location;
  firstPositionChangeLocation: Location;
  positionDescription: string;
  willNotShow: boolean;
  beacon: Beacon;
  isHidden: boolean;

  pickUpLocation: Location;
  dropOffLocation: Location;
  class: Class;
  users: User[];
  parents: User[];

  // FE props
  isInWrongBus = false;

  constructor(data: any = {}) {
    super(data);

    if (data.beacon) {
      this.beacon = new Beacon(data.beacon);
    } else {
      // NOTE: we've to create bacon just to sent that the position is NOT_IN_BUS
      // this flag should stay in the child model not in the beacon
      this.beacon = new Beacon({ position: EChildPosition.NOT_IN_BUS });
    }

    if (data.location) {
      this.location = new Location(data.location);
    }

    if (data.firstPositionChangeLocation) {
      this.firstPositionChangeLocation = new Location(data.firstPositionChangeLocation);
    }

    if (data.usesr) {
      this.users = data.users.map(u => new User(u));
    }
    if (data.pickUpLocation) {
      this.pickUpLocation = new Location(data.pickUpLocation);
    }
    if (data.dropOffLocation) {
      this.dropOffLocation = new Location(data.dropOffLocation);
    }

    if (data.class) {
      this.class = new Class(data.class);
    }
    if (data.birthDate && !(data.birthDate instanceof Date)) {
      this.birthDate = new Date(data.birthDate);
    }
    if (data.childrenLists) {
      this.childrenLists = data.childrenLists.map(childrenList => new ChildrenList(childrenList));
    }
    if (data.parents) {
      this.parents = data.parents.map(parent => new User(parent));
    }
  }

  get childrenList(): ChildrenList {
    if (this.childrenLists && this.childrenLists.length) {
      const activeChildrenList = this.childrenLists.find(childrenList => !!childrenList.routePlan);
      if (!!activeChildrenList) {
        return activeChildrenList;
      } else {
        return this.childrenLists[0];
      }
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  // this is added to prevent errors
  set fullName(fullName: string) {}

  get parent() {
    if (!this.users) {
      return null;
    }
    return this.users.find((user: User) => user.role === EUserRoles.PARENT);
  }

  get positionIcon() {
    if (this.willNotShow) {
      return {
        icon: EChildPositionIcon['WILL_NOT_SHOW'],
        color: EChildPositionIconColor['WILL_NOT_SHOW'],
        tooltip: EChildPositionTooltip['WILL_NOT_SHOW'],
      };
    }
    return this.beacon.positionIcon;
  }

  get serialised() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullName,
      gender: this.gender,
      birthDate: this.birthDate && getDateWithoutTime(this.birthDate),
      fiscalCode: this.fiscalCode,
      nation: this.nation,
      province: this.province,
      city: this.city,
      pickUpLocationId: this.pickUpLocationId,
      dropOffLocationId: this.dropOffLocationId,
      classId: (this.class && this.class.id) || this.classId || undefined,
      class: this.class && this.class.serialised,
      userId: this.userId,
      message: this.message,
      location: this.location && this.location.serialised,
      firstPositionChangeLocation:
        this.firstPositionChangeLocation && this.firstPositionChangeLocation.serialised,
      positionDescription: this.positionDescription,
      willNotShow: this.willNotShow,
      pickUpLocation: this.pickUpLocation && this.pickUpLocation.serialised,
      dropOffLocation: this.dropOffLocation && this.dropOffLocation.serialised,
      users: this.users && this.users.map(u => u.serialised),
      beacon: this.beacon && this.beacon.serialised,
      // parents: this.parents && this.parents.map(parent => parent.serialised),
      // parent: this.parent && this.parent.serialised,
    };
  }
}
