import { Component, OnInit } from '@angular/core';

import { IBitfLoaderAction, IBitfLoaderComponent } from '@interfaces';
import { EBitfLoaderAction } from '@enums';
import { LoaderService } from '@services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'bitf-loader',
  templateUrl: './bitf-loader.component.html',
  styleUrls: ['./bitf-loader.component.scss'],
})
export class BitfLoaderComponent implements OnInit, IBitfLoaderComponent {
  canShowLoader = false;
  loaderData: any;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.onLoaderChange.subscribe((loaderAction: IBitfLoaderAction) => {
      switch (loaderAction.action) {
        case EBitfLoaderAction.SHOW:
          this.loaderData = loaderAction.data;
          this.show();
          this.loaderService.isLoading = true;
          break;
        case EBitfLoaderAction.HIDE:
          this.loaderData = undefined;
          this.hide();
          this.loaderService.isLoading = false;
          break;
      }
    });
  }

  show() {
    setTimeout(() => (this.canShowLoader = true));
  }

  hide() {
    setTimeout(() => (this.canShowLoader = false));
  }
}
