import { Injectable, Injector } from '@angular/core';
import { BitfApiCallStateService } from '@bitf/services/api-call-state/bitf-api-call-state.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCallStateService extends BitfApiCallStateService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
