export enum EChildPosition {
  IN_BUS = 'IN_BUS',
  NOT_IN_BUS = 'NOT_IN_BUS',
}

export enum ERouteType {
  TO_SCHOOL = 'TO_SCHOOL',
  FROM_SCHOOL = 'FROM_SCHOOL',
}

export enum EGender {
  M = 'M',
  F = 'F',
}

export enum EBatteryLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum EChildPositionTooltip {
  IN_BUS = 'COMMON.LABEL.IN_BUS',
  NOT_IN_BUS = 'COMMON.LABEL.NOT_IN_BUS',
  WILL_NOT_SHOW = 'COMMON.STATUSES.WILL_NOT_SHOW',
  NOT_ACTIVATED = 'COMMON.STATUSES.NOT_ACTIVATED',
}
