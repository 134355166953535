import { BITF_CONFIGS as COMMON_BITF_CONFIGS } from '@common/configs/common-bitf.configs';
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';

const WEB_BITF_CONFIGS = {
  urls: { homePageUrl: '/children', signInUrl: '/sign-in', signOutUrl: '/sign-in' },
  loaderService: {},
  serviceWorker: { checkUpdateInterval: 30000 },
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
}; // getAuthTokenComponent: { signInComponent: LoginLoaderComponent },

export const BITF_CONFIGS = Object.assign(COMMON_BITF_CONFIGS, WEB_BITF_CONFIGS);
