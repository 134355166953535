import { OnInit, Input, OnDestroy, Injector } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ApiCallStateService } from '@services';
import {
  IBitfApiRequestPartChips,
  IBitfApiRequestPartsChipValue,
  IBitfUiApiRequestPartChip,
  IBitfApiCallState,
} from '@interfaces';
import { EBitfApiCallStateActions } from '@enums';
import { BitfApiRequestPart } from '../../../api-call-state/bitf-api-request-part';

export class BitfApiRequestPartsChipsComponent implements OnInit, OnDestroy {
  @Input()
  apiCallStateName: string;
  @Input()
  showResetButton: any;
  @Input()
  removable = true;
  @Input()
  mergeMultiple = false;
  @Input()
  trimMultiple = true;

  @Input()
  excludedRequestPartsItems = [];

  private apiCallStateService: ApiCallStateService;

  chips: Array<IBitfUiApiRequestPartChip> = [];

  subscriptions: Subscription = new Subscription();
  constructor(public injector: Injector) {
    this.apiCallStateService = injector.get(ApiCallStateService);
  }

  ngOnInit() {
    this.subscriptions.add(
      this.apiCallStateService
        .getApiCallStateOservable(this.apiCallStateName)
        .subscribe(() => this.setIChips())
    );
    this.setIChips();
  }

  setIChips() {
    const apiCallState = this.apiCallStateService.getApiCallState(this.apiCallStateName);
    this.chips = [];

    if (!apiCallState || !apiCallState.requestParts || !apiCallState.requestParts.length) {
      return;
    }
    const chipsCreator = this.mergeMultiple ? this.createMergedChips : this.createStandarChips;
    const tempChips: Array<IBitfUiApiRequestPartChip> = [];
    apiCallState.requestParts
      .filter((apiRequestPart: BitfApiRequestPart) => apiRequestPart.chips)
      .forEach((apiRequestPart: BitfApiRequestPart) => {
        apiRequestPart.chips.forEach((requestPartChip: IBitfApiRequestPartChips) => {
          if (this.excludedRequestPartsItems.includes(requestPartChip.apiRequestPartItemName)) {
            return;
          }
          tempChips.push(...chipsCreator.call(this, requestPartChip));
        });
      });
    this.chips = tempChips;
  }

  createStandarChips(requestPartChip: IBitfApiRequestPartChips): Array<IBitfUiApiRequestPartChip> {
    return requestPartChip.chip.map(val => {
      return {
        apiRequestPartItemName: requestPartChip.apiRequestPartItemName,
        label: requestPartChip.label,
        chipValue: val.value,
        chipKey: val.key,
        apiRequestPartKey: requestPartChip.apiRequestPartKey,
      } as IBitfUiApiRequestPartChip;
    });
  }

  createMergedChips(requestPartChip: IBitfApiRequestPartChips): Array<IBitfUiApiRequestPartChip> {
    const chipsValueCreator = this.trimMultiple ? this.createTrimmedChipsValue : this.createFullChipsValue;
    return [
      {
        apiRequestPartItemName: requestPartChip.apiRequestPartItemName,
        label: requestPartChip.label,
        chipKey: requestPartChip.chip.map(v => v.key),
        ...chipsValueCreator(requestPartChip),
      } as IBitfUiApiRequestPartChip,
    ];
  }

  createTrimmedChipsValue(requestPartChip: IBitfApiRequestPartChips): IBitfApiRequestPartsChipValue {
    return {
      chipValue: String(requestPartChip.chip[0].value),
      tooltipValue:
        requestPartChip.chip.length > 1 ? requestPartChip.chip.map(v => v.value).join(', ') : null,
    } as IBitfApiRequestPartsChipValue;
  }

  createFullChipsValue(requestPartChip: IBitfApiRequestPartChips): IBitfApiRequestPartsChipValue {
    return {
      chipValue: requestPartChip.chip.map(v => String(v.value)).join(', '),
      tooltipValue: null,
    } as IBitfApiRequestPartsChipValue;
  }

  removeChip(chipToRemove: IBitfUiApiRequestPartChip) {
    const apiRequestPart: BitfApiRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      chipToRemove.apiRequestPartKey
    );

    if (!apiRequestPart) {
      return;
    }

    const chipKeys = chipToRemove.chipKey instanceof Array ? chipToRemove.chipKey : [chipToRemove.chipKey];

    apiRequestPart.data = this.removeValueFromDatasource(
      apiRequestPart.data,
      chipKeys,
      chipToRemove.apiRequestPartItemName
    );
    apiRequestPart.formValue = this.removeValueFromDatasource(
      apiRequestPart.formValue,
      chipKeys,
      chipToRemove.apiRequestPartItemName
    );

    this.apiCallStateService.dispatchApiCallState(EBitfApiCallStateActions.UPDATE, this.apiCallStateName);
  }

  removeValueFromDatasource(dataSource: any, chipKeys: Array<any>, apiRequestPartName: string) {
    if (!dataSource[apiRequestPartName]) {
      return dataSource;
    }
    if (dataSource[apiRequestPartName] instanceof Array) {
      dataSource[apiRequestPartName] = dataSource[apiRequestPartName].filter(
        val => !(val['id'] && chipKeys.includes(val['id']))
      );
      if (!dataSource[apiRequestPartName].length) {
        delete dataSource[apiRequestPartName];
      }
    } else {
      delete dataSource[apiRequestPartName];
    }
    return dataSource;
  }

  resetAll() {
    this.apiCallStateService.dispatchApiCallState(
      EBitfApiCallStateActions.RESET_ALL_FILTERS,
      this.apiCallStateName
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
