import { BitfApiCallStateMapper } from '@bitf/core/api-call-state/bitf-api-call-state.mapper';
import { IBitfApiCallState } from '@interfaces';

export class AdminDefaultApiCallStateMapper extends BitfApiCallStateMapper {
  buildApiRequest(apiCallState: IBitfApiCallState): void {
    apiCallState.apiRequest.filter = this.computedApiRequestParts.filter
      .filter(filterString => filterString)
      .join(' and ');
  }
}
