import { SuperModel } from './super-model.model';
import { Child } from './child.model';
import { School } from './school.model';
import { RoutePlan } from '@common/core/models/route-plan.model';

export class ChildrenList extends SuperModel {
  name: string;
  schoolId: number;
  children: Child[];
  school: School;
  routePlans?: RoutePlan[];

  constructor(data: any = {}) {
    super(data);

    if (data.children && data.children.length) {
      this.children = data.children.map(child => new Child(child));
    }

    if (data.school) {
      this.school = new School(data.school);
    }

    if (data.routePlans) {
      this.routePlans = data.routePlans.map(routePlan => new RoutePlan(routePlan));
    }
  }

  get routePlan(): RoutePlan {
    return this.routePlans && this.routePlans.find(routePlan => !!routePlan.currentDriverId);
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      children: this.children && this.children.map((child: Child) => child.serialised),
      schoolId: this.schoolId,
    };
  }
}
