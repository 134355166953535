<div class="row cm-fixed-viewport">
  <div class="col cm-admin-crud-presenter-panel m-3 shadow">
    <div class="row border-bottom justify-content-between align-items-center py-2">
      <div class="col-auto cm-admin-crud-presenter-panel-title">{{ listTitle }}</div>

      <div class="col-auto">
        <div class="row no-gutters align-items-center">
          <cm-admin-search *ngIf="searchSettings"
            class="col-auto mr-2"
            [apiCallStateName]="apiCallStateName"
            [searchSettings]="searchSettings"></cm-admin-search>

          <div class="col-auto">
            <mat-divider [vertical]="true">
            </mat-divider>
          </div>

          <button *ngIf="!filtersHidden"
            class="col-auto"
            (click)="filter.emit({})"
            mat-icon-button>
            <mat-icon matPrefix>tune</mat-icon>
          </button>

          <div class="col-auto">
            <button mat-flat-button
              color="accent"
              (click)="create.emit({})">{{ 'COMMON.BUTTON.ADD' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mt-2">
        <cm-admin-filters-chips [apiCallStateName]="apiCallStateName"></cm-admin-filters-chips>
      </div>
    </div>
    <div class="row flex-grow-1">
      <mat-list role="list"
        class="col ml-2 crud-presenter-list">
        <mat-list-item *ngFor="let item of items; trackBy: trackByFn"
          role="listitem"
          class="row justify-content-between align-items-center py-1 border-bottom"
          (click)="selectable && listItemSelected.emit(item)"
          [ngClass]="{'selected-item': selectedItem && item.id === selectedItem.id}">
          <div class="col">
            <ng-content *ngTemplateOutlet="listItemTemplate; context: {item: item}"></ng-content>
          </div>
          <div class="col-auto"
            *ngIf="selectable">
            <button mat-icon-button>
              <mat-icon>
                chevron_right</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="row"
      *ngIf="pagination">
      <div class="col">
        <mat-paginator [length]="pagination.totalItems"
          [pageSize]="pagination.size"
          [pageIndex]="pagination.page - 1 "
          [pageSizeOptions]="pageSizeOptions"
          (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>

  <div *ngIf="rightBoxVisible"
    class="col-13 cm-admin-crud-presenter-panel m-3 ml-0 shadow">
    <div class="row border-bottom justify-content-between align-items-center py-2">
      <div class="col-auto cm-admin-crud-presenter-panel-title">{{ editTitle }}</div>
      <div class="col-auto">
        <button *ngIf="editable"
          (click)="edit.emit({})"
          mat-icon-button>
          <mat-icon matPrefix
            color="accent">edit</mat-icon>
        </button>
        <button *ngIf="removable"
          (click)="delete.emit({})"
          mat-icon-button>
          <mat-icon matPrefix
            color="warn">delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ng-content *ngTemplateOutlet="itemDetailsTemplate"></ng-content>
      </div>
    </div>
  </div>
</div>