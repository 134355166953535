import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';

export class AdminSearchApiRequestPart extends BitfApiRequestPart {
  constructor() {
    super(EApiRequestPartKeys.SEARCH, EBitfApiRequestPartsNames.FILTER);
  }

  build(): void {
    this.part = undefined;
    if (!this.data.keyword) {
      return;
    }

    if (!this.data.searchSettings.searchForEachWord) {
      this.part = this.getSearchFilterForKeyword(
        this.data.keyword,
        this.data.searchSettings.searchExpression
      );
    } else {
      this.part = `(
        ${this.data.keyword
          .split(' ')
          .map(keywordStr =>
            this.getSearchFilterForKeyword(keywordStr, this.data.searchSettings.searchExpression)
          )
          .join(' or ')}
      )`;
    }
  }

  getSearchFilterForKeyword(keyword: string, searchExpression: string) {
    return `(${searchExpression.replace(new RegExp('{{keyword}}', 'g'), keyword.toLowerCase())})`;
  }
}
