export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
}

export enum ERoles {
  MASTER = 'MASTER',
}

export enum ERoleActions {
  VIEW_LOG = 'VIEW_LOG',
}

export enum EUserRoles {
  ADMIN = 'ADMIN',
  PARENT = 'PARENT',
  DRIVER = 'DRIVER',
  ASSISTANT = 'ASSISTANT',
}
