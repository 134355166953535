import justClone from 'just-clone';

import { IBitfApiRequestPartChips, IBitfPartData, IBitfStoreParts } from '@interfaces';
import { EBitfApiRequestPartsNames } from '@enums';

export type UpdateStorFn = (fn: (storeParts: IBitfStoreParts) => void) => void;

export abstract class BitfApiRequestPart {
  private _part: any;
  private _storeSection: IBitfStoreParts = {};
  chips?: Array<IBitfApiRequestPartChips>;

  constructor(
    public key: string,
    public partName: EBitfApiRequestPartsNames,
    public defaultPartData: IBitfPartData = { data: {}, formValue: {} }
  ) {
    this.reset();
  }

  init(storeParts: IBitfStoreParts) {
    const merged = Object.assign({}, { [this.key]: justClone(this.defaultPartData) }, storeParts);
    this._storeSection = Object.assign(storeParts, merged);
  }

  get data() {
    return this._storePart.data;
  }

  // NOTE: must be treated as immutable, no change properties, re assign the object
  set data(data: any) {
    this._storePart.data = data;
  }

  get formValue() {
    return this._storePart.formValue;
  }

  // NOTE: must be treated as immutable, no change properties, re assign the object
  set formValue(formValue: any) {
    this._storePart.formValue = formValue;
  }

  get part() {
    return this._part;
  }

  set part(part: any) {
    this._part = part;
  }

  abstract build(): void;

  reset() {
    this.part = undefined;
    this.chips = [];
    this._storeSection[this.key] = Object.assign({}, this.defaultPartData);
  }

  clear() {
    this.part = undefined;
    this.chips = [];
    this._storeSection[this.key] = { data: {}, formValue: {} };
  }

  private get _storePart(): IBitfPartData {
    return this._storeSection[this.key];
  }
}
