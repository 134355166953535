import { BitfStore } from '@bitf/core/models/bitf-store.model';

import { User } from '@models';
import { Bus } from '@models';

export class AdminStore extends BitfStore {
  user: User;
  dashboardSelectedBus: Bus;
  isFollowingAllDashboardBuses = false;

  constructor(data: any) {
    super(data);
  }
}
