export enum EBitfApiRequestPartsNames {
  HEADERS = 'headers',
  QUERY_PARAMS = 'queryParams',
  ACTION_PARAMS = 'actionParams',
  QUERY = 'query',
  SEARCH = 'search',
  FILTER = 'filter',
  EMBED = 'embed',
  COMPUTED_FIELDS = 'computedFields',
  SORTING = 'sorting',
  DATA = 'data',
  PAGINATION = 'pagination',
}
