import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { IBitfToastData, IBitfToastMessagesService } from '@interfaces';
import { BitfToastMessagesService } from '../bitf-toast-messages.service';
import { EBitfToastHorizontalPosition, EBitfToastVerticalPosition } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class BitfMatToastMessageService extends BitfToastMessagesService
  implements IBitfToastMessagesService {
  constructor(protected matSnackBar: MatSnackBar) {
    super();
  }

  show(data: IBitfToastData) {
    let messageToDisplay = data.title;
    if (data.message) {
      if (data.title) {
        messageToDisplay += ' | ';
      }
      messageToDisplay += data.message;
    }

    const options = {
      duration: data.duration || this.defaultToastData.duration,
      horizontalPosition: data.horizontalPosition || EBitfToastHorizontalPosition.CENTER,
      verticalPosition: data.verticalPosition || EBitfToastVerticalPosition.BOTTOM,
    };
    this.matSnackBar.open(messageToDisplay, null, options);
  }

  clearAllMessages() {}
}
