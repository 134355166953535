import { SuperModel } from './super-model.model';
import { Company } from './company.model';
import { Location } from './location.model';
import { User } from './user.model';

export class Bus extends SuperModel {
  companyId: string;
  plate: string;
  company?: Company;
  location?: Location;
  driver?: User;
  dashboardLabel: string;
  numOfChildrenOnBoard: number;

  constructor(data: any = {}) {
    super(data);
    if (data.company) {
      this.company = new Company(data.company);
    }
    if (data.location) {
      this.location = new Location(data.location);
    }
    if (data.user) {
      this.driver = new User(data.location);
    }
  }

  get serialised() {
    return {
      id: this.id,
      companyId: this.companyId,
      plate: this.plate,
      location: this.location && this.location.serialised,
      dashboardLabel: this.dashboardLabel,
      numOfChildrenOnBoard: this.numOfChildrenOnBoard,
      // company: this.company && this.company.serialised,
    };
  }
}
