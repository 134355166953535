import { Bus } from './bus.model';

export class PublicApi {
  buses: Bus[];
  companyId: number;

  constructor(data: any = {}) {
    Object.assign(this, data);

    if (data.buses && data.buses.length) {
      this.buses = data.buses.map((bus: Bus) => new Bus(bus));
    }
  }

  get serialised() {
    return {};
  }
}
