import { Component } from '@angular/core';

import { AuthService } from '@services';
@Component({
  selector: 'cm-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class SignOutComponent {
  constructor(private authService: AuthService) {
    this.authService.signOut();
  }
}
