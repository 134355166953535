import { SuperModel } from './super-model.model';

export class Company extends SuperModel {
  name: string;

  get serialised() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
