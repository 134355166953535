import { environment } from '@env/environment';
import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';
export function BitfTryCatch(message?) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    let descriptorType = 'value';
    if (descriptor.get && typeof descriptor.get === 'function') {
      descriptorType = 'get';
    } else if (descriptor.set && typeof descriptor.set === 'function') {
      descriptorType = 'set';
    }
    const decoratorFunctionFactory = _decoratorFunctionFactory.bind(this);
    let originalFunction;
    if (descriptorType === 'value') {
      originalFunction = descriptor[descriptorType];
      descriptor.value = decoratorFunctionFactory(originalFunction, target, propertyKey);
    } else {
      originalFunction = descriptor.get;
      descriptor.get = decoratorFunctionFactory(originalFunction, target, propertyKey);
      originalFunction = descriptor.set;
      descriptor.set = decoratorFunctionFactory(originalFunction, target, propertyKey);
    }
  };
}

function _decoratorFunctionFactory(originalFunction, target, propertyKey) {
  return function(...args) {
    try {
      return originalFunction.apply(this, args);
    } catch (error) {
      if (!environment.production) {
        console.error(error);
      }
      // NOTE: wait the app finish to load
      setTimeout(() => {
        const errorHandlerService = window['rootInjector'].get(BitfErrorHandlerService);
        errorHandlerService.handle(error, {
          fromDecorator: true,
          className: target.constructor.name,
          functionName: propertyKey,
        });
      }, 0);
    }
  };
}
