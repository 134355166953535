import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { of, Observable } from 'rxjs';
import { switchMap, catchError, delay, repeat } from 'rxjs/operators';

import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';

import { PublicApi } from '@models';
import { IBitfApiResponse, IBitfApiRequest } from '@web/interfaces';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicApiService extends BitfApiService {
  // mockApiUrl = environment.mockApiUrl;
  // mockApiParser = 'mockDbParser';

  busPollingTime = 6000;

  constructor(protected injector: Injector, private bitfErrorHandler: BitfErrorHandlerService) {
    super('public-api', injector);
  }

  getBuses(apiRequest: IBitfApiRequest): Observable<IBitfApiResponse<PublicApi>> {
    return this.action<PublicApi>({
      apiUrl: environment.publicApiUrl,
      action: '/dashboard-info',
      modelMapper: 'public-api',
      ...apiRequest,
    });
  }

  busesPolling(apiRequest: IBitfApiRequest): Observable<IBitfApiResponse<PublicApi>> {
    return of(1).pipe(
      switchMap(() => this.getBuses(apiRequest)),
      catchError(e => {
        this.bitfErrorHandler.handleError(e);
        return of({ content: new PublicApi({ buses: [], companyId: undefined }) });
      }),
      delay(this.busPollingTime),
      repeat()
    );
  }
}
