<mat-form-field class="w-100 admin-class-autocomplete"
  [hideRequiredMarker]="readonly">
  <mat-select [multiple]="multiple"
    [formControl]="classFormControl"
    [compareWith]="compareObjects"
    [disabled]="readonly"
    [placeholder]="placeholder"
    [errorStateMatcher]="errorStateMatcher"
    [required]="required">
    <mat-option [disabled]="true"
      class="admin-class-autocomplete-input">
      <mat-form-field appearance="outline"
        class="w-100 p-0 m-0">
        <input matInput
          (keydown)="$event.stopPropagation()"
          [placeholder]="placeholder"
          [formControl]="searchInput"
          [readonly]="readonly">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </mat-option>
    <mat-option [selected]="true"
      *ngFor="let sClass of filteredClasses | async"
      [value]="sClass">
      {{ sClass.name }}
    </mat-option>
  </mat-select>
  <mat-error
    *ngIf="formControlInstance && formControlInstance.touched && formControlInstance.invalid && formControlInstance.hasError('required')">
    {{ 'COMMON.VALIDATION.REQUIRED' | translate }}</mat-error>
</mat-form-field>