export enum EAdminCreateUpdateRouteSteps {
  GENERAL = 'general',
  TO_SCHOOL = 'toSchool',
  FROM_SCHOOL = 'fromSchool',
  ROUTE = 'route',
  STAFF = 'staff',
  BUS = 'bus',
  SUMMARY = 'summary',
}

export enum EAdminCreateUpdateRouteMode {
  CREATE = 'create',
  UPDATE = 'update',
}
