import { SuperModel } from './super-model.model';
import { Class } from './class.model';

export class School extends SuperModel {
  name: string;
  classes: Array<Class>;

  constructor(data: any = {}) {
    super(data);
    if (data.classes) {
      this.classes = data.classes.map(sClass => new Class(sClass));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      classes: this.classes && this.classes.map((sClass: Class) => sClass.serialised),
    };
  }
}
