import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { BitfDatePipe } from '@common/libs/bitforce/pipes/bitf-date.pipe';

// tslint:disable-next-line:max-line-length
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';

const SHARED_MODULES = [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule];
const SHARED_PIPES = [BitfDatePipe];
const SHARED_COMPONENTS = [];
const SHARED_DIRECTIVES = [BitfUiRoleManagerDirective];
const SHARED_BITF_COMPONENTS = [];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_BITF_COMPONENTS,
    ...SHARED_PIPES,
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_BITF_COMPONENTS, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonSharedModule {
  // This should only be called inside the CoreModule!
}
