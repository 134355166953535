<mat-toolbar>
  <mat-toolbar-row class="pl-2">
    <div class="row justify-content-between align-items-center w-100 no-gutters">
      <div class="col-auto">
        <div class="row justify-content-between align-items-center">
          <button mat-icon-button
            (click)="openMenu()"
            class="mx-2 col-auto"
            tabindex="-1">
            <mat-icon>menu</mat-icon>
          </button>
          <span class="col-auto">{{ appSessionService.texts.header | translate }}</span>
        </div>
      </div>
      <div class="col"
        *ngIf="headerExtraContentTemplate">
        <ng-container *ngTemplateOutlet="headerExtraContentTemplate"></ng-container>
      </div>
      <a *ngIf="!headerExtraContentTemplate"
        class="col-auto"
        mat-flat-button>{{'COMMON.HI' | translate}}, {{user?.firstName}} {{user?.lastName}} - Role:
        {{user?.role}}</a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>