export enum EBitfCloseEventStatus {
  OK,
  CANCEL,
  CLOSE,
  ERROR,
}

export enum EBitfBrowser {
  OPERA = 'OPERA',
  FIREFOX = 'FIREFOX',
  SAFARI = 'SAFARI',
  SAFARI_MOBILE = 'SAFARI_MOBILE',
  IE = 'IE',
  EDGE = 'EDGE',
  CHROME = 'CHROME',
  BLINK = 'BLINK',
  NOT_DEFINED = 'NOT_DEFINED',
}
