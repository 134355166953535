<div class="row align-items-center">
  <div class="col-auto">
    <mat-chip-list #chipList
      *ngIf="chips">
      <mat-chip *ngFor="let chip of chips"
        [removable]="removable || !mergeMultiple"
        (removed)="removeChip(chip)">
        {{chip.label | translate}}:&nbsp;
        <strong>{{chip.chipValue | translate}}</strong>
        <mat-icon *ngIf="removable"
          matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div class="col-auto ml-0"
    *ngIf="showResetButton">
    <button mat-flat-button
      (click)="resetAll()"
      *ngIf="chip.length">
      {{ 'COMMON.BUTTON.RESET_ALL' | translate }}
    </button>
  </div>
</div>