import { Injector, Injectable } from '@angular/core';

import { IBitfJsonBatchRequest, IBitfApiRequest } from '@interfaces';
import { BitfApiService } from './bitf-api.service';

export class BitfODataBatchService extends BitfApiService {
  constructor(injector: Injector) {
    super('$batch', injector);
  }

  jsonBatch(data: IBitfJsonBatchRequest[], options: IBitfApiRequest = {}) {
    const requests = data.map((datum, i) => ({
      id: datum.id || '' + (i + 1),
      atomicityGroup: datum.atomicityGroup || 'group1',
      method: datum.method,
      url: `${datum.rawUrl ? '' : this.helper.getApiUrl()}${datum.url}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      dependsOn: datum.dependsOn,
      body: datum.body,
    }));
    const request = Object.assign(
      {
        method: 'POST',
        isBodyRaw: true,
        headers: [
          { headerName: 'OData-Version', value: '4.0' },
          { headerName: 'Accept', value: 'application/json' },
        ],
        modelMapper: 'actionData',
        body: {
          requests,
        },
      },
      options
    );
    return this.request<any>(request);
  }
}
