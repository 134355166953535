import { Component, OnInit, Injector } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { BitfApiRequestPartsChipsComponent } from '@bitf/core/components/ui/api-request-parts-chips/bitf-api-request-parts-chips.component';

@Component({
  selector: 'cm-admin-filters-chips',
  templateUrl: './admin-filters-chips.component.html',
  styleUrls: ['./admin-filters-chips.component.scss'],
})
export class AdminFiltersChipsComponent extends BitfApiRequestPartsChipsComponent {
  constructor(public injector: Injector) {
    super(injector);
  }
}
