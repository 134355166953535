<div class="w-100 h-100 d-flex flex-column justify-content-between p-3 p-md-0 py-md-3">

  <div class="d-flex justify-content-between align-items-center">
    <h3 class="m-0">{{'ADMIN.SCHOOLBUS.LIST_TITLE' | translate}}</h3>
    <button mat-icon-button
      class="d-block d-md-none"
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="flex-grow-1 children-list">
    <mat-nav-list>
      <mat-list-item *ngFor="let bus of buses; trackBy:trackByFn"
        class="border-bottom p-0"
        [ngClass]="{'selected': storeService.store.dashboardSelectedBus ? storeService.store.dashboardSelectedBus.id === bus.id : false}"
        (click)="onBusClick(bus)"
        (mouseenter)="busMouseEnter.emit(bus)"
        (mouseleave)="busMouseLeave.emit(bus)">
        <div mat-line
          class="d-flex align-items-center justify-content-between">
          <!-- <img src="/assets/icons/bus_icon.svg"
            width="38"
            height="38" /> -->
          <!-- <div class="pl-2">
              <span class="d-block mat-caption"> {{bus.driver?.firstName}} {{bus.driver?.lastName}} </span>
            </div> -->
          <h4 class="m-0 text-truncate"
            matTooltip="{{bus.dashboardLabel || bus.plate}}">{{'COMMON.LABEL.SCHOOLBUS' | translate}}:
            {{bus.dashboardLabel || bus.plate}}</h4>
          <mat-icon class="unfollow"
            [ngClass]="{'follow': isFollowingMe(bus) }">
            near_me
          </mat-icon>
        </div>
        <div matLine>
          <span class="mat-caption">
            <span>{{'COMMON.LABEL.NUMBER_OF_CHILDREN_ON_BOARD' | translate}}</span>:
            <span class="font-weight-bold">
              {{bus.numOfChildrenOnBoard}}
            </span>
          </span>
        </div>
        <div mat-line>
          <span class="mat-caption">
            {{'COMMON.LABEL.DETECTED_AT' | translate}}:
            {{bus.location.createdAt | date:'dd-MM-yyyy HH:mm:ss'}}
          </span>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </div>

  <div class="d-flex">
    <div class="col-12 px-1">
      <button mat-raised-button
        [color]="getFollowAllBusesColor"
        class="w-100"
        (click)="onFollowAllBuses()">
        {{'COMMON.LABEL.FOLLOW_ALL' | translate}}
      </button>
    </div>

    <div class="col-12 px-1">
      <button mat-raised-button
        [color]="getStopFollowAllBusesColor"
        class="w-100"
        (click)="onStopFollowAllBuses()">
        {{'COMMON.LABEL.UNFOLLOW' | translate}}
      </button>
    </div>
  </div>

</div>