import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';
import { EUserRoles } from '@enums';
// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';
import { environment } from '@env/environment';

const auth0ClientId = {
  development: '029JI16wcmkLQFEsNeoEqAEU7Tmrc0dx',
  'remote-development': '029JI16wcmkLQFEsNeoEqAEU7Tmrc0dx',
  staging: 'pv356lm8o7libklllc5Y8TfWKq5cYovB',
  production: '9p7Dzwc5de3Xc0kg2wgV0DjXWNycI7Xo',
};

const auth0Domain = {
  development: 'children-monitor-dev.eu.auth0.com',
  'remote-development': 'children-monitor-dev.eu.auth0.com',
  staging: 'children-monitor-test.eu.auth0.com',
  production: 'children-monitor.eu.auth0.com',
};

export const ADMIN_CONSTANTS = {
  appName: 'Children monitor - admin app',
  fullScreenMessageComponent: null,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  pageSizeOptions: [5, 10, 25, 100],
  auth0Config: {
    clientID: auth0ClientId[environment.name] || auth0ClientId.development,
    domain: auth0Domain[environment.name] || auth0Domain.development,
    redirectUri: `${location.origin}/sign-in`,
    returnTo: `${location.origin}/sign-in`,
    responseType: 'token id_token',
    scope: 'openid',
    audience: environment.audience,
  },
  allowedUserTypes: [EUserRoles.ADMIN],
  userRole: EUserRoles.ADMIN,
  LOCAL_STORAGE_VERSION: 1,
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, ADMIN_CONSTANTS);
