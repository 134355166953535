import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

// TODO we should provide a default toast similar to loader
import { ToastMessagesService, LoaderService } from '@services';
import { BitfGenericService } from '@bitf/services/api/bitf-generic.service';
import { IBitfApiResponse, IBitfApiAction } from '@interfaces';
import { EBitfUiMessageType } from '@enums';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'bitf-redirect',
  templateUrl: './bitf-redirect.component.html',
  styleUrls: ['./bitf-redirect.component.scss'],
})
export class BitfRedirectComponent implements OnInit {
  apiCall: string;
  redirectTo: string;

  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private genericService: BitfGenericService,
    private router: Router,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit() {
    this.loaderService.show();
    this.apiCall = this.route.snapshot.queryParams['apiCall'];
    this.redirectTo = this.route.snapshot.queryParams['redirectTo'];

    if (this.apiCall) {
      this.genericService.action({ path: this.apiCall }).subscribe(
        (response: IBitfApiResponse<IBitfApiAction>) => {
          if (response.content.success) {
            this.loaderService.hide();
            setTimeout(() => this.router.navigateByUrl(this.redirectTo));
          } else {
            this.errorRedirect();
          }
        },
        error => {
          this.errorRedirect();
        }
      );
    } else if (this.redirectTo) {
      this.router.navigateByUrl(this.redirectTo);
    } else {
      this.router.navigate(['']);
    }
  }

  errorRedirect() {
    this.loaderService.hide();
    this.toastMessagesService.show({
      title: 'Error',
      message: 'Incorrect redirect params',
      type: EBitfUiMessageType.ERROR,
    });
    setTimeout(() => this.router.navigate(['']));
  }
}
