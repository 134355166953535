<div class="d-flex flex-column w-100 h-100">
  <div class="d-flex justify-content-between align-items-center pb-4">
    <h2 class="mb-0 pb-0 align-items-center">{{'ADMIN.DIALOG.LABEL.CHILD_LAST_POSITION' | translate}}</h2>
    <button mat-button
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="pb-2">
    {{childPositionDescription}} {{child.location?.createdAt | date:'short'}}
  </div>
  <div class="cm-map"
    #googleMap></div>
</div>