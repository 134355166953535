<div class="admin-sign-in d-flex flex-column justify-content-center align-items-center">

  <img src="assets/logos/logo_blu.png">

  <!-- <div class="mat-display-2 text-center">{{ 'CORE.WELCOME_PAGE.TITLE' | translate }}</div> -->

  <h1 class="text-center"> {{ 'CORE.WELCOME_PAGE.DESCRIPTION' | translate }}</h1>

  <div *ngIf="authState === eBitfAuthState.TOKEN_RETRIEVED"
    class="mb-3">
    <h2>{{ 'LOGIN.IN_PROGRESS_PLEASE_WAIT' | translate }}</h2>
    <mat-progress-bar mode="indeterminate"
      color="accent"></mat-progress-bar>
  </div>

  <div *ngIf="authState === eBitfAuthState.EMAIL_NOT_VERIFIED"
    class="py-3 text-center">
    <h2 class="m-0">{{ 'BITF.AUTH.EMAIL_NOT_VERIFIED' | translate }}</h2>
  </div>

  <div *ngIf="authState === eBitfAuthState.LOGIN_ERROR"
    class="py-3 text-center">
    <h2>{{ 'LOGIN.ERROR' | translate }}</h2>
  </div>

  <div class="row justify-content-center w-100"
    *ngIf="authState === eBitfAuthState.TO_BE_LOGGED || authState === eBitfAuthState.LOGIN_ERROR || authState === eBitfAuthState.EMAIL_NOT_VERIFIED">
    <div class="col-5">
      <button mat-flat-button
        color="accent"
        class="w-100 mb-3 text-uppercase"
        (click)="onSignIn()">
        {{ 'LOGIN.BUTTON.SIGN_IN' | translate }}
      </button>
    </div>
  </div>

  <div class="row justify-content-center w-100"
    *ngIf="authState === eBitfAuthState.LOGIN_ERROR || authState === eBitfAuthState.EMAIL_NOT_VERIFIED">
    <div class="col-5">
      <button mat-flat-button
        color="accent"
        class="w-100 mb-3 text-uppercase"
        (click)="onChangeAccount()">
        {{ 'LOGIN.BUTTON.CHANGE_ACCOUNT' | translate }}
      </button>
    </div>
  </div>

  <div class="row justify-content-center">
    <a class="col-auto"
      href="https://www.childrenmonitor.it/admin-app-privacy-policy.html"
      target="_blank">{{'COMMON.APP.PRIVACY_POLICY' | translate}}</a>
  </div>

  <div class="row justify-content-center mt-3">
    <div class="col-24 text-center">
      <span color="medium">V{{APP_VERSION}}</span>
      <span *ngIf="!environment.production"> - {{environment.name}}</span>
    </div>
  </div>
</div>