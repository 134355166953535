import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  Injector,
} from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { CmMatSelectErrorStateMatcher } from '@common/shared/utils/cm-mat-select-error-state-matcher';

import { Class } from '@models';

@Component({
  selector: 'cm-admin-class-autocomplete',
  templateUrl: './admin-class-autocomplete.component.html',
  styleUrls: ['./admin-class-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AdminClassAutocompleteComponent,
      multi: true,
    },
  ],
})
export class AdminClassAutocompleteComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input()
  set classes(classes: Array<Class>) {
    this._classes = classes;
    this.valueChanged('');
    this.searchInput.setValue('');
    this.classFormControl.reset();
  }

  @Input()
  placeholder = '';

  @Input()
  readonly = false;

  @Input()
  multiple = false;

  @Input()
  formControlInstance: FormControl;

  @Input()
  required = false;

  @Output()
  classSelected: EventEmitter<Class | string> = new EventEmitter<Class | string>();

  @ViewChild('matSelect', { static: false })
  matSelect: ElementRef;

  private _classes: Array<Class> = [];
  searchInput: FormControl = new FormControl();
  classFormControl: FormControl = new FormControl({});
  filteredClasses: Observable<Array<Class>>;
  hasBeenTouched = false;
  selectedClasses: Array<Class> = [];
  subscription: Subscription = new Subscription();
  errorStateMatcher: CmMatSelectErrorStateMatcher;

  onChange: (data: any) => void;
  onTouched: () => void;

  constructor(private injector: Injector) {}

  get classes(): Array<Class> {
    return this._classes || [];
  }

  ngOnInit() {
    this.errorStateMatcher = new CmMatSelectErrorStateMatcher();
    if (this.formControlInstance) {
      this.errorStateMatcher.isErrorState = (control, form): boolean => {
        return this.formControlInstance.invalid && this.formControlInstance.touched;
      };
    }

    this.filteredClasses = this.searchInput.valueChanges.pipe(
      startWith<string | Class>(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this.filterClasses(name) : this.classes.slice()))
    );
    this.subscription.add(
      this.classFormControl.valueChanges.subscribe(selectedItems => {
        if (selectedItems) {
          this.markAsTouched();
        }
        this.valueChanged(selectedItems);
      })
    );
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.valueChanged(event.option.value);
  }

  displaySelectedClass(sClass?: Class) {
    return sClass ? sClass.name : '';
  }

  private valueChanged(newValue: Class | string) {
    if (!this.hasBeenTouched) {
      return;
    }

    this.classSelected.emit(newValue);
    if (this.onChange) {
      this.onChange(newValue);
    }
  }

  public markAsTouched() {
    if (!this.hasBeenTouched) {
      this.hasBeenTouched = true;
      this.classFormControl.markAsTouched();
      if (this.onTouched) {
        this.onTouched();
      }
    }
  }

  compareObjects(object1: any, object2: any): boolean {
    if (!(object1 && object2 && object1['id'] && object2['id'])) {
      return false;
    }
    return object1.id === object2.id;
  }

  reset() {
    this.classFormControl.reset();
  }

  writeValue(obj: any): void {
    this.classFormControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.classFormControl.disable();
    } else {
      this.classFormControl.enable();
    }
  }

  private filterClasses(keyword: string) {
    return this.classes.filter((sClass: Class) => sClass.name.toLowerCase().includes(keyword.toLowerCase()));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
