import { HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { IBitfEnvelopeMapperData, IBitfApiResponse, IBitfApiPagination, IBitfApiSorting } from '@interfaces';
import { BitfRestEnvelopeMapper } from '@bitf/parsers/rest-parser/bitf-rest-envelope.mapper';
export class BitfMockDbEnvelopeMapper extends BitfRestEnvelopeMapper {
  constructor() {
    super();
  }

  map({ req, event }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    event = super.map({ req, event });
    const mappedBody: IBitfApiResponse<any> = event.body;
    const originalBody = event.body.originalBody;

    if (originalBody != null && Array.isArray(originalBody)) {
      mappedBody.content = originalBody;
      if (Array.isArray(originalBody)) {
        mappedBody.pagination = this.mapPagination(originalBody, req.params, event.headers);
        mappedBody.sorting = this.mapSorting(req.params);
      }
    } else {
      mappedBody.content = originalBody;
    }

    mappedBody.isMockResponse = true;
    return event.clone<IBitfApiResponse<any>>({
      body: mappedBody,
    });
  }

  private mapPagination(content: any, httpParams: HttpParams, headers: HttpHeaders): IBitfApiPagination {
    const totalItems = parseInt(headers.get('x-total-count'), 10);
    if (!totalItems) {
      return null;
    }
    const page = this.getHttpParamAsInt(httpParams, '_page', 1);
    const size = this.getHttpParamAsInt(httpParams, '_limit', 10);
    const totalPages = Math.ceil(totalItems / size);
    return {
      page,
      size,
      totalItems,
      totalPages,
      first: page === 1,
      last: page === totalPages,
      itemsInPage: content instanceof Array ? content.length : 0,
    } as IBitfApiPagination;
  }

  private mapSorting(httpParams: HttpParams): IBitfApiSorting[] {
    if (!httpParams.get('_sort')) {
      return null;
    }
    return [
      {
        property: httpParams.get('_sort'),
        direction: httpParams.get('_order'),
      } as IBitfApiSorting,
    ];
  }

  private getHttpParamAsInt(httpParams: HttpParams, paramName: string, defaultValue = 0) {
    const paramVal = httpParams.get(paramName);
    return paramVal ? parseInt(paramVal, 10) : defaultValue;
  }
}
