import { OnInit, Type, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { BitfGenericService } from '@bitf/services/api/bitf-generic.service';
import { BitfMatSidenavService } from '@bitf/services/sidenav/material/bitf-mat-sidenav.service';
import { BitfPwaService } from '@bitf/services/pwa/bitf-pwa.service';
import { AuthService, StoreService, AppSessionService, DialogsService } from '@services';
import { CONSTANTS } from '@constants';
import { User } from '@models';
import { EBitfSidenavPosition, EBitfSidenavMode, EStoreActions, EBitfOnlineStates } from '@enums';
// import { BITF_CONFIGS } from '@configs';
// import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export abstract class CommonHeaderComponent implements OnInit {
  abstract menuComponent: Type<any>;

  authService: AuthService;
  translate: TranslateService;
  dialogsService: DialogsService;
  appSessionService: AppSessionService;
  genericService: BitfGenericService;
  mSidenavService: BitfMatSidenavService;
  storeService: StoreService;
  bitfPwaService: BitfPwaService;

  user: User;
  selectedLanguage;
  supportedLanguages = CONSTANTS.SUPPORTED_LANGUAGES;
  eBitfOnlineStates = EBitfOnlineStates;

  constructor(public injector: Injector) {
    this.authService = this.injector.get(AuthService);
    this.storeService = this.injector.get(StoreService);
    this.translate = this.injector.get(TranslateService);
    this.dialogsService = this.injector.get(DialogsService);
    this.appSessionService = this.injector.get(AppSessionService);
    this.genericService = this.injector.get(BitfGenericService);
    this.mSidenavService = this.injector.get(BitfMatSidenavService);
    this.bitfPwaService = this.injector.get(BitfPwaService);
  }

  ngOnInit() {
    this.user = this.storeService.store.user;
    this.storeService.store$
      .pipe(
        filter(data => data.action === EStoreActions.UPDATE_USER),
        filter(data => data.store.user && !!data.store.user.id)
      )
      .subscribe(data => {
        this.user = data.store.user;
      });
  }

  // NOTE: enable this when we'll enable translations
  // useLanguage(event) {
  //   const dialogRef = this.dialogsService.dialog.open(BITF_CONFIGS.okCancelDialogComponent, {
  //     width: '90%',
  //     maxWidth: '800px',
  //     data: {
  //       title: bitfToTranslate('BUDGET.DIALOG.CHANGE_LANGUAGE.TITLE'),
  //       message: bitfToTranslate('BUDGET.DIALOG.CHANGE_LANGUAGE.DESCRIPTION'),
  //       cancelText: bitfToTranslate('COMMON.BUTTON.NO'),
  //       okText: bitfToTranslate('COMMON.BUTTON.OK'),
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (!result) {
  //       return;
  //     }
  //   });
  // }

  openMenu() {
    this.mSidenavService.open({
      component: this.menuComponent,
      sidenavOptions: { mode: EBitfSidenavMode.OVER, position: EBitfSidenavPosition.LEFT },
    });
  }
}
