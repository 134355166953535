import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// tslint:disable-next-line: max-line-length
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { IBitfCloseEvent, IBitfToastData } from '@interfaces';
import {
  EBitfCloseEventStatus,
  EBitfUiMessageType,
  EBitfToastHorizontalPosition,
  EStoreActions,
  EAdminStoreActions,
} from '@enums';
import { ToastMessagesService, StoreService } from '@services';

import { Bus } from '@models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cm-bus-tracker-list',
  templateUrl: './bus-tracker-list.component.html',
  styleUrls: ['./bus-tracker-list.component.scss'],
})
export class BusTrackerListComponent implements OnInit {
  @Input()
  buses: Bus[];
  @Input()
  inSidenav = false;

  @Output()
  busMouseEnter: EventEmitter<Bus> = new EventEmitter<Bus>();
  @Output()
  busMouseLeave: EventEmitter<Bus> = new EventEmitter<Bus>();

  constructor(
    private sidenavService: BitfMatSidenavService,
    private toastMessageService: ToastMessagesService,
    private translateService: TranslateService,
    private storeService: StoreService
  ) {}

  ngOnInit() {}

  get getFollowAllBusesColor() {
    return this.storeService.store.isFollowingAllDashboardBuses ? 'primary' : '';
  }

  get getStopFollowAllBusesColor() {
    return !this.storeService.store.isFollowingAllDashboardBuses &&
      !this.storeService.store.dashboardSelectedBus
      ? 'primary'
      : '';
  }

  onBusClick(bus: Bus) {
    if (bus.location.latitude === 0 && bus.location.longitude === 0) {
      this.toastMessageService.show({
        title: this.translateService.instant('ADMIN.SCHOOLBUS.NOT_DETECTED'),
        type: EBitfUiMessageType.ERROR,
        horizontalPosition: EBitfToastHorizontalPosition.LEFT,
      } as IBitfToastData);
      return;
    }

    this.updateBusTracker(bus, false);

    if (this.inSidenav) {
      this.sidenavService.close({
        status: EBitfCloseEventStatus.OK,
        data: bus,
      } as IBitfCloseEvent<Bus>);
    }
  }

  onFollowAllBuses() {
    this.updateBusTracker(undefined, true);

    if (this.inSidenav) {
      this.sidenavService.close({
        data: this.buses,
        status: EBitfCloseEventStatus.OK,
      } as IBitfCloseEvent<Bus[]>);
    }
  }

  onStopFollowAllBuses() {
    this.updateBusTracker(undefined, false);

    if (this.inSidenav) {
      this.sidenavService.close({
        status: EBitfCloseEventStatus.OK,
      } as IBitfCloseEvent<void>);
    }
  }

  close() {
    this.sidenavService.close({
      status: EBitfCloseEventStatus.CLOSE,
    });
  }

  trackByFn(_, item) {
    return item.id;
  }

  isFollowingMe(bus: Bus) {
    return (
      this.storeService.store.isFollowingAllDashboardBuses ||
      (this.storeService.store.dashboardSelectedBus &&
        this.storeService.store.dashboardSelectedBus.id === bus.id)
    );
  }

  private updateBusTracker(bus: Bus | undefined, isFollowingAllBuses: boolean) {
    this.storeService.setStore(store => {
      store.dashboardSelectedBus = bus;
      store.isFollowingAllDashboardBuses = isFollowingAllBuses;
    }, EAdminStoreActions.UPDATE_BUS_TRACKER);
  }
}
