import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BitfAuthGuard } from '@bitf/services/guards/bitf-auth.guard';

import { EBitfUiMessageType } from '@enums';
import { ToastMessagesService, StoreService } from '@services';
import { IBitfToastData } from '@interfaces';
import { CONSTANTS } from '@constants';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BitfAuthGuard {
  constructor(
    protected injector: Injector,
    protected storeService: StoreService,
    protected toastMessageService: ToastMessagesService,
    protected translateService: TranslateService
  ) {
    super(injector);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const bitfCanActivate = super.canActivate(next, state);
    if (typeof bitfCanActivate !== 'boolean') {
      // NOTE: Is an obserable
      return (bitfCanActivate as Observable<boolean>).pipe(
        switchMap(() => {
          return of(this.checkUserRoutePermission(next, state));
        })
      );
    }
    // NOTE: Is a boolean
    if (bitfCanActivate) {
      return this.checkUserRoutePermission(next, state);
    }
    return bitfCanActivate;
  }

  checkUserRoutePermission(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (CONSTANTS.allowedUserTypes.includes(this.storeService.store.user.role)) {
      return true;
    } else {
      this.toastMessageService.show({
        title: this.translateService.instant('COMMON.ERROR'),
        message: this.translateService.instant('AUTH.ACCESS_DENIED'),
        type: EBitfUiMessageType.ERROR,
      } as IBitfToastData);
      setTimeout(() => {
        // NOTE: let the user show the toast, the sign out will do the redirect straight!
        this.authService.signOut();
      }, 4000);
    }
    return false;
  }
}
