import { IBitfApiRequest, IBitfApiSorting, IBitfApiODataRequestMapped } from '@interfaces';
import { BitfRestRequestMapper } from '../rest-parser/bitf-rest-request.mapper';
import { bitfCapitalize } from '@bitf/utils/bitf-string.utils';
import { EBitfParsers } from '@enums';

export abstract class BitfODataRequestMapper extends BitfRestRequestMapper {
  name = EBitfParsers.BITF_ODATA_PARSER;

  constructor() {
    super();
  }

  map(requestParams: IBitfApiRequest): IBitfApiODataRequestMapped {
    const { search, filter, sorting, page, size, count, embed } = requestParams;
    const requestMapped: IBitfApiODataRequestMapped = super.map(requestParams);
    const params: any = {};

    if (filter) {
      params.$filter = filter;
    }

    if (search) {
      params.$search = search;
    }

    if (embed && embed.length) {
      params.$expand = embed.map(s => bitfCapitalize(s)).join(',');
    }

    if (sorting && sorting.length) {
      params.$orderby = this.mapSort(sorting);
    }

    if (page !== undefined && size !== undefined) {
      params.$skip = (page - 1) * size;
      params.$top = size;
    }

    if (page !== undefined || size !== undefined) {
      params.$count = 'true';
    }

    if (count) {
      params.$count = true;
    }

    requestMapped.params = requestMapped.params || {};
    if (Object.keys(params).length) {
      Object.assign(requestMapped.params, params);
    }

    // NOTE: this is an hack to add odata required body to requestParams
    if (requestParams.relationRefId) {
      requestParams.isBodyRaw = true;
      requestParams.body = {
        '@odata.id': requestParams.relationRefId,
      };
    }

    return requestMapped as IBitfApiODataRequestMapped;
  }

  mapSort(sorting: IBitfApiSorting[]) {
    return sorting.map(s => `${bitfCapitalize(s.property)} ${s.direction.toLowerCase()}`).join(',');
  }
}
