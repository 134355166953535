import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { Location } from '@models';
import { LoaderService } from '@services';

@Component({
  selector: 'cm-admin-location-search',
  templateUrl: './admin-location-search.component.html',
  styleUrls: ['./admin-location-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AdminLocationSearchComponent,
      multi: true,
    },
  ],
})
export class AdminLocationSearchComponent implements OnInit, ControlValueAccessor {
  @Input()
  showRequiredError = false;

  @Input()
  placeholder = '';

  @Output()
  locationChanged: EventEmitter<Location> = new EventEmitter<Location>();

  @ViewChild('input', { static: true })
  input: ElementRef;

  searchInput: FormControl = new FormControl();
  currentLocation: Location;

  onChange: (value: any) => void;
  onTouched: () => void;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    const gPlacesAutocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, {
      types: ['geocode'],
    });
    gPlacesAutocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = gPlacesAutocomplete.getPlace();
      if (place && place.geometry) {
        this.currentLocation = new Location({
          address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
        this.onChange(this.currentLocation);
      }
    });
  }

  writeValue(obj: any): void {
    if (!obj || !(obj instanceof Location)) {
      this.currentLocation = undefined;
      this.searchInput.setValue('');
      return;
    }
    this.currentLocation = obj;
    this.searchInput.patchValue(obj.address);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
