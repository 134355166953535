export enum EBitfApiSortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
  UNDEFINED = '',
}

// Note order matter
export enum EBitfUiMessageType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum EBitfUiMessageTarget {
  TOAST = 'TOAST',
  VALIDATION = 'VALIDATION',
  DIALOG = 'DIALOG',
  CONSOLE = 'CONSOLE',
  FULL_SCREEN = 'FULL_SCREEN',
}

export enum EBitfApiCallStateActions {
  UPDATE = 'UPDATE',
  UPDATE_API_CALL_STATE = 'UPDATE_API_CALL_STATE',
  RESET_ALL_FILTERS = 'RESET_ALL_FILTERS',
}
