import { Location } from './location.model';

export class RouteLocation extends Location {
  time?: string;
  order?: number;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      ...new Location(this).serialised,
      time: this.time,
      order: this.order,
    };
  }
}
