import { Injectable, Injector } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';
import { User } from '@models';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DriversService extends BitfApiService {
  mockApiUrl = environment.mockApiUrl;
  mockApiParser = 'mockDbParser';

  constructor(protected injector: Injector) {
    super('drivers', injector);
  }

  getCurrentDrivers(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<User[]>> {
    return this.fetch({
      ...requestParams,
      path: '/current-drivers',
      method: 'GET',
    }).pipe(map(envelope => this.helper.mapEnvelope<User[]>(envelope, requestParams)));
  }
}
