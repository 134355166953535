<h2 mat-dialog-title>{{ 'BITF.DIALOG.ADD_TO_HOMESCREEN.TITLE' | translate }}</h2>
<mat-dialog-content>{{ 'BITF.DIALOG.ADD_TO_HOMESCREEN.CONTENT' | translate }}</mat-dialog-content>
<mat-dialog-actions class="row"
  class="justify-content-end">
  <button mat-flat-button
    mat-dialog-close>{{ 'BITF.LABEL.NO' | translate }}</button>
  <button mat-flat-button
    (click)="prompt($event)"
    color="primary"
    [mat-dialog-close]="true">
    {{ 'BITF.LABEL.OK' | translate }}
  </button>
</mat-dialog-actions>