import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-admin-not-found',
  templateUrl: './admin-not-found.component.html',
  styleUrls: ['./admin-not-found.component.scss'],
})
export class AdminNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
