import { Component, Injector } from '@angular/core';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { CommonMenuComponent } from '@common/core/components/menu/common-menu.component';
import { EBitfCloseEventStatus } from '@enums';
import { IMenuItem } from '@interfaces';
import { AuthService } from '@services';
@Component({
  selector: 'cm-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent extends CommonMenuComponent {
  menuItems: Array<IMenuItem> = [
    {
      icon: 'dashboard',
      label: bitfToTranslate('ADMIN.MENU.DASHBOARD'),
      url: '/dashboard',
    },
    {
      icon: 'school',
      label: bitfToTranslate('ADMIN.MENU.SCHOOLS'),
      url: '/schools',
    },
    {
      icon: 'people',
      label: bitfToTranslate('ADMIN.MENU.CHILDREN'),
      url: '/children',
    },
    {
      icon: 'face',
      label: bitfToTranslate('ADMIN.MENU.CHILDREN_LIST'),
      url: '/children-lists',
    },
    {
      icon: 'account_circle',
      label: bitfToTranslate('ADMIN.MENU.STAFF'),
      url: '/staff',
    },
    {
      icon: 'directions_bus',
      label: bitfToTranslate('ADMIN.MENU.SCHOOL_BUS_LIST'),
      url: '/buses',
    },
    {
      icon: 'place',
      label: bitfToTranslate('ADMIN.MENU.ROUTES_LIST'),
      url: '/routes',
    },
  ];

  authService: AuthService;

  constructor(public injector: Injector) {
    super(injector);
    this.authService = injector.get(AuthService);
  }

  signOut() {
    this.mSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
    this.authService.signOut();
  }
}
