import { Injectable, Injector, TemplateRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Subject } from 'rxjs';

import { BitfPwaService } from '@bitf/services/pwa/bitf-pwa.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';
import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';

import { CONSTANTS } from '@constants';
import { EStoreActions } from '@enums';
import { Store } from '@models';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  texts = {
    header: CONSTANTS.DEFAULT_APP_HEADER,
  };

  headerExtraContentTemplate$: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();

  set headerText(headerText: string) {
    // NOTE: this is to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.texts.header = headerText;
    });
    this.bitfMetadataService.updateTitle(headerText);
  }

  set headerExtraContentTemplate(headerExtracContentTemplate: TemplateRef<any>) {
    this.headerExtraContentTemplate$.next(headerExtracContentTemplate);
  }

  constructor(
    public injector: Injector,
    private bitfPwaService: BitfPwaService,
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private breakpointObserver: BreakpointObserver
  ) {
    super(injector);
  }

  init() {
    super.init();

    this.initBreakpointListener();

    this.bitfPwaService.initManifest();
  }

  initSession() {}

  initSettings() {}

  initLogSender() {
    this.bitfLogDnaSenderService.init();
  }

  initBreakpointListener() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(result => {
        if (result.matches) {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = true;
            store.activeBreakpoints.isMobile = false;
          }, EStoreActions.BREACKPOINT);
        } else {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = false;
            store.activeBreakpoints.isMobile = true;
          }, EStoreActions.BREACKPOINT);
        }
      });
  }

  cleanSession() {}
}
