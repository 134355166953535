import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { BitfAuth0AuthService } from '@bitf/services/auth/auth0/bitf-auth0-auth.service';
import { BITF_CONFIGS } from '@configs';
import { User } from '@models';
import { EBitfAuthState, EUserRoles } from '@enums';
import { UsersService } from '@services';
import { IBitfApiResponse, IBitfApiRequest } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthService extends BitfAuth0AuthService {
  usersService: UsersService;
  router: Router;

  constructor(protected injector: Injector) {
    super(injector);
    this.usersService = this.injector.get(UsersService);
    this.router = this.injector.get(Router);

    this.initAuth0();

    this.authState$.subscribe((authState: EBitfAuthState) => {
      if (authState === EBitfAuthState.TOKEN_RETRIEVED) {
        this.usersService.getMe().subscribe(
          (response: IBitfApiResponse<User>) => {
            this.setUser(response.content);
            this.onLoginSuccess();
            this.handleRedirect();
          },
          errror => {
            this.onLoginError();
          }
        );
      }
    });
  }

  handleRedirect() {
    this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
  }
}
