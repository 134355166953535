import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export const CONSTANTS = {
  FULL_PAGE_VIEWS: ['/sign-in', '/sign-up', '/forgot-password', '/afterauth'],
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [{ code: 'EN', label: 'English' }, { code: 'IT', label: 'Italiano' }],
  DEFAULT_LANGUAGE: { code: 'EN', label: 'English' },
  MAP_CONF: {
    DEFAULT_OPTIONS: {
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: new google.maps.LatLng(0, 0),
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    },
    DIRECTIONS_RENDERER_OPTIONS: {
      polylineOptions: {
        strokeColor: '#0288D1',
        strokeWeight: 5,
        strokeOpacity: 1,
        geodesic: true,
      },
    },
  },
  NOTIFIACTIONS_MINUTES_BEFORE: [5, 10, 15, 20, 25, 30],
  NOTIFIACTIONS_MINUTES_BEFORE_DEFAULT: 5,
  DEFAULT_APP_HEADER: bitfToTranslate('COMMON.HEADER.TEXT'),
};
