export enum EBitfToastVerticalPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum EBitfToastHorizontalPosition {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  LEFT = 'left',
  RIGHT = 'right',
}
