import { SuperModel } from './super-model.model';
import { EUserRoles } from '@enums';

export class User extends SuperModel {
  static defaultValues = {
    notifyBeforeMinutes: 5,
  };

  firstName: string;
  lastName: string;
  email: string;
  role: EUserRoles;
  notifyWhenInNurserySchool: boolean;
  emailNotificationEnabled?: boolean;
  whatsappNotificationEnabled?: boolean;
  notifyPickUp?: boolean;
  notifyDropOff?: boolean;
  notifyBeforeMinutes?: number;
  phoneNumber?: string;
  currentRouteId?: string;
  currentBusId?: string;
  companyId?: string;
  address?: string;
  alternativePhoneNumber?: string;
  city: string;
  fiscalCode: string;
  birthDate: string;
  postCode: string;
  province: string;

  constructor(data: any = {}) {
    super(data);
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  set fullName(fullName: string) {}

  get serialised() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullName,
      email: this.email,
      role: this.role,
      phoneNumber: this.phoneNumber,
      notifyPickUp: this.notifyPickUp,
      notifyDropOff: this.notifyDropOff,
      emailNotificationEnabled: this.emailNotificationEnabled,
      notifyBeforeMinutes: this.notifyBeforeMinutes,
      currentRouteId: this.currentRouteId,
      currentBusId: this.currentBusId,
      companyId: this.companyId,
      address: this.address,
      alternativePhoneNumber: this.alternativePhoneNumber,
      city: this.city,
      fiscalCode: this.fiscalCode,
      birthDate: this.birthDate,
      postCode: this.postCode,
      province: this.province,
    };
  }
}
