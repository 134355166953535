<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <ng-container #sidenavContent></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <cm-admin-header *ngIf="showHeader"></cm-admin-header>
    <div class="container-fluid"
      [class.cm-content-without-header]="!showHeader">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<bitf-loader></bitf-loader>