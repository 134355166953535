import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { Observable } from 'rxjs';

import { IBitfApiRequest, IBitfApiResponse, IBitfApiAction } from '@interfaces';
import { ChildrenList } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ChildrenListsService extends BitfApiService {
  // mockApiUrl = 'http://localhost:3001/';
  // mockApiParser = 'mockDbParser';

  constructor(protected injector: Injector) {
    super('children-lists', injector);
  }

  setChildren(request: IBitfApiRequest): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.request<IBitfApiAction>({
      ...request,
      method: 'POST',
      path: '/set-children',
      isBodyRaw: true,
    });
  }

  getBySchoolId(request: IBitfApiRequest): Observable<IBitfApiResponse<ChildrenList[]>> {
    return this.get<ChildrenList>({
      filter: `schoolId eq ${request.data.schooldId}`,
    });
  }
}
