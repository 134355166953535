import { SuperModel } from './super-model.model';
import { Location } from '@models';
import {
  EChildPositionIcon,
  EChildPositionIconColor,
  EChildPositionTooltip,
  EChildPosition,
  EBatteryPercentageIcon,
  EBatteryPercentageIconColor,
} from '@enums';
export class Beacon extends SuperModel {
  name: string;
  address: string;
  position: EChildPosition;
  batteryPercentage?: number;
  rssi?: number;
  age?: number;
  deltaReads?: number;
  txPower?: number;
  advertisementData?: string;
  url?: string;
  distance?: number;
  temperature?: number;
  voltage?: number;
  location?: Location;

  constructor(data: Partial<Beacon> = {}) {
    super(data);
    if (data.location) {
      this.location = new Location(data.location);
    }

    if (this.address === '') {
      this.address = null;
    }

    if (!data.createdAt) {
      this.createdAt = new Date();
    } else {
      this.createdAt = new Date(this.createdAt);
    }
  }

  get positionIcon() {
    if (!this.position) {
      return {};
    }
    if (!this.address) {
      return {
        icon: EChildPositionIcon['NOT_ACTIVATED'],
        color: EChildPositionIconColor['NOT_ACTIVATED'],
        tooltip: EChildPositionTooltip['NOT_ACTIVATED'],
      };
    }
    return {
      icon: EChildPositionIcon[this.position],
      color: EChildPositionIconColor[this.position],
      tooltip: EChildPositionTooltip[this.position],
    };
  }

  get batteryPercentageIcon() {
    switch (true) {
      case this.batteryPercentage > 0 && this.batteryPercentage < 20:
        return { icon: EBatteryPercentageIcon['_0_20'], color: EBatteryPercentageIconColor['_0_20'] };
      case this.batteryPercentage >= 20 && this.batteryPercentage < 30:
        return { icon: EBatteryPercentageIcon['_20_30'], color: EBatteryPercentageIconColor['_20_30'] };
      case this.batteryPercentage >= 30 && this.batteryPercentage < 50:
        return { icon: EBatteryPercentageIcon['_30_50'], color: EBatteryPercentageIconColor['_30_50'] };
      case this.batteryPercentage >= 50 && this.batteryPercentage < 60:
        return { icon: EBatteryPercentageIcon['_50_60'], color: EBatteryPercentageIconColor['_50_60'] };
      case this.batteryPercentage >= 60 && this.batteryPercentage < 80:
        return { icon: EBatteryPercentageIcon['_60_80'], color: EBatteryPercentageIconColor['_60_80'] };
      case this.batteryPercentage >= 80 && this.batteryPercentage < 90:
        return { icon: EBatteryPercentageIcon['_80_90'], color: EBatteryPercentageIconColor['_80_90'] };
      case this.batteryPercentage >= 90 && this.batteryPercentage < 95:
        return { icon: EBatteryPercentageIcon['_90_95'], color: EBatteryPercentageIconColor['_90_95'] };
      case this.batteryPercentage >= 95:
        return { icon: EBatteryPercentageIcon['_95_100'], color: EBatteryPercentageIconColor['_95_100'] };
      default:
        return { icon: EBatteryPercentageIcon['DEFAULT'], color: EBatteryPercentageIconColor['DEFAULT'] };
    }
  }

  get serialised() {
    return {
      createdAt: this.createdAt && (this.createdAt as Date).toISOString(),
      name: this.name,
      address: this.address && this.address.toUpperCase(),
      position: this.position,
      batteryPercentage: this.batteryPercentage,
      rssi: this.rssi,
    };
  }
}
