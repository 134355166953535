import { OnInit, ViewChild, ViewContainerRef, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BitfMatSidenavGlobalService } from '@bitf/services/sidenav/material/bitf-mat-sidenav-global.service';

export abstract class CommonLayout implements OnInit, OnDestroy {
  showHeader = true;
  showFooter = true;

  @ViewChild('sidenav', { static: true })
  sidenav: MatSidenav;
  @ViewChild('sidenavContent', { read: ViewContainerRef, static: true })
  sidenavContent;
  subscription: Subscription = new Subscription();

  route: ActivatedRoute;
  router: Router;
  mSidenavGlobalService: BitfMatSidenavGlobalService;

  constructor(public injector: Injector) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.mSidenavGlobalService = injector.get(BitfMatSidenavGlobalService);
  }

  abstract updateLayout(): void;

  ngOnInit() {
    this.mSidenavGlobalService.init(this.sidenav, this.sidenavContent);
    this.updateLayout();
    this.subscription.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.updateLayout();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
