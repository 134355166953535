<div class="d-flex flex-column cm-admin-menu p-1 px-3">
  <div>
    <div class="row justify-content-between no-gutters mt-3">
      <img class="rounded-circle col-auto"
        width="48"
        height="48"
        src="https://picsum.photos/48/48" />
      <button mat-flat-button
        (click)="closeSidenav()"
        class="col-auto menu-item-button"
        tabindex="-1">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="m-0 mt-3 mat-subheading-2">{{ user?.firstName }} {{ user?.lastName }}</div>
  <div class="m-0 mb-3 pb-2 border-bottom border-light mat-subheading-1 ">{{ user?.role }}</div>

  <div class="cm-scroll-y flex-grow-1">
    <button mat-flat-button
      *ngFor="let menuPosition of menuItems"
      class="w-100 text-left pl-1 mt-2 menu-item-button"
      [routerLink]="menuPosition.url"
      routerLinkActive="active-link"
      (click)="closeSidenav()">
      <mat-icon matPrefix>{{ menuPosition.icon }}
      </mat-icon>
      <span class="pl-3">{{ menuPosition.label | translate }}</span>
    </button>
  </div>

  <div class="cm-admin-menu-footer">
    <button mat-flat-button
      (click)="signOut()"
      class="text-center w-100 logout-button">
      {{ 'COMMON.BUTTON.LOGOUT' | translate }}
    </button>

    <div class="text-center py-2">
      <a class="p-0"
        mat-button
        href="https://www.childrenmonitor.it/admin-app-privacy-policy.html"
        target="_blank">{{'COMMON.APP.PRIVACY_POLICY' | translate}}</a>
    </div>

    <div class="mat-caption mt-1 text-center pt-1">{{ 'COMMON.APP.VERSION' | translate }}: {{ appVersion }}
    </div>
  </div>
</div>