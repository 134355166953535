import {
  Component,
  OnInit,
  Inject,
  ComponentFactoryResolver,
  ComponentFactory,
  ViewContainerRef,
  ViewChild,
  ComponentRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EBitfCloseEventStatus, EBitfApiCallStateActions } from '@enums';

import { IAdminDialogFilterData } from '@interfaces';
import { AdminDialogComponent } from '../dialog/admin-dialog.component';
import { ApiCallStateService } from '@services';
import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

@Component({
  selector: 'cm-admin-dialog-filter',
  templateUrl: './admin-dialog-filter.component.html',
  styleUrls: ['./admin-dialog-filter.component.scss'],
})
export class AdminDialogFilterComponent extends AdminDialogComponent<AdminDialogFilterComponent>
  implements OnInit {
  @ViewChild('filtersContainer', { read: ViewContainerRef, static: true })
  filtersContainer: ViewContainerRef;

  filterComponent: ComponentRef<any>;

  constructor(
    public dialogRef: MatDialogRef<AdminDialogFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public filterData: IAdminDialogFilterData,
    private resolver: ComponentFactoryResolver,
    private apiCallStateService: ApiCallStateService
  ) {
    super();
  }

  ngOnInit() {
    const componentFactory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
      this.filterData.filterComponent
    );
    this.filterComponent = this.filtersContainer.createComponent(componentFactory);
    this.filterComponent.instance.setFilter(this.getFilterRequestPart());
  }

  onFilter() {
    const updatedfilterData = this.filterComponent.instance.getFilter();
    const filter = this.getFilterRequestPart();
    Object.assign(filter, updatedfilterData);
    this.apiCallStateService.dispatchApiCallState(
      EBitfApiCallStateActions.UPDATE,
      this.filterData.apiCallStateName
    );

    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: {},
    });
  }

  getFilterRequestPart(): BitfApiRequestPart {
    return this.apiCallStateService.getRequestPart(
      this.filterData.apiCallStateName,
      this.filterData.filterKey
    );
  }
  onReset() {
    this.filterComponent.instance.reset();
    this.onFilter();
  }
}
