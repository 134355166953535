import { Injectable, Injector } from '@angular/core';
import { BitfODataBatchService } from '@bitf/services/api/bitf-odata-batch.service';

@Injectable({
  providedIn: 'root',
})
export class BatchService extends BitfODataBatchService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
